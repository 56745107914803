.AnaesthetistReview__section-errors {
  margin: 0 0 20px;
  padding: 0;
  list-style: none;
}

.AnaesthetistReview__section-error {
  color: #ff0000;
  margin: 0 0 5px;
}

.AnaesthetistReview__section-content {
  overflow-x: auto;
}

.AnaesthetistReview__section-disclaimer {
  font-size: 14px;
}

@media only screen and (min-width: 450px) {
  .AnaesthetistReview__section-content {
    overflow-x: visible;
  }
}

.AnaesthetistReview__value-readonly,
.AnaesthetistReview__value-input[type='text'] {
  width: 100%;
}

.AnaesthetistReview__value-readonly {
  display: block;
}

.AnaesthetistReview__value-input {
  min-height: 60px;
  padding: 10px;
  display: none;
  resize: vertical;
  box-sizing: border-box;
  cursor: pointer;
}

.AnaesthetistReview__value-input-radio {
  position: relative;
}

.AnaesthetistReview__section-editing .AnaesthetistReview__value-readonly {
  display: none;
}

.AnaesthetistReview__section-editing
  .AnaesthetistReview__value-input[type='text'] {
  display: block;
}

.AnaesthetistReview__value-input[type='radio'] {
  position: absolute;
  opacity: 0;
  z-index: 1;
  width: 24px;
  height: 24px;
  top: -8px;
  left: -3px;
}

.AnaesthetistReview__section-editing
  .AnaesthetistReview__value-input[type='radio'] {
  display: inline-block;
  vertical-align: baseline;
}

.AnaesthetistReview__section-editing textarea.AnaesthetistReview__value-input {
  display: inline-block;
  width: 100%;
  vertical-align: top;
}

.AnaesthetistReview__section-actions {
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  margin-top: 10px;
}

.AnaesthetistReview__section-actions .button + button {
  margin-left: 10px;
}

.AnaesthetistReview__section-heading {
  display: flex;
  justify-content: flex-start;
  padding: 10px 0;
}

.AnaesthetistReview__section-heading-toggled {
  margin-bottom: 10px;
}

.AnaesthetistReview__section-heading .AnaesthetistReview__section-toggle {
  cursor: pointer;
  position: relative;
  top: 3px;
  margin-left: auto;
  margin-right: 5px;
  background: transparent;
  width: 10px;
  height: 10px;
  display: inline-block;
  border-right: 4px solid #00556e;
  border-bottom: 4px solid #00556e;
  transition: transform 0.2s ease;
  transform: rotate(45deg);
}

.AnaesthetistReview__section-heading .AnaesthetistReview__section-toggle-off {
  transform: rotate(225deg);
}

.AnaesthetistReview__section-heading .AnaesthetistReview__section-toggle:focus {
  outline: none;
}

.AnaesthetistReview__data-grid {
  display: flex;
  flex-flow: row wrap;
  padding: 0;
  margin: 0;
}

.AnaesthetistReview__data-grid li {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  background: white;
  flex-basis: 100%;
}

.AnaesthetistReview__data-grid li:nth-child(odd) {
  background: #dcf2f8;
}

@media (min-width: 450px) {
  .AnaesthetistReview__data-grid li {
    flex-basis: 50%;
  }

  .AnaesthetistReview__data-grid li:nth-child(odd) {
    background: white;
  }

  .AnaesthetistReview__data-grid li:nth-child(4n + 1),
  .AnaesthetistReview__data-grid li:nth-child(4n + 2) {
    background: #dcf2f8;
  }
}

.AnaesthetistReview__data-grid-cell {
  width: 50%;
  padding: 5px 10px 5px 0;
  flex-grow: 0;
  box-sizing: border-box;
}

.AnaesthetistReview__data-grid-cell .AnaesthetistReview__value-input {
  min-height: 0;
  padding: 3px;
}

.AnaesthetistReview__data-grid-full {
  flex: none;
}

.AnaesthetistReview__data-grid-full li {
  flex-basis: 100%;
}

.AnaesthetistReview__data-grid-full li:nth-child(4n + 1),
.AnaesthetistReview__data-grid-full li:nth-child(4n + 2) {
  background: white;
}

.AnaesthetistReview__data-grid-full li:nth-child(odd) {
  background: #dcf2f8;
}

.AnaesthetistReview__data-grid li:nth-child(odd):last-child,
.AnaesthetistReview__data-grid-full li:nth-child(odd):last-child {
  flex-basis: 100%;
}

.AnaesthetistReview__data-grid
  li:nth-child(odd):last-child
  .AnaesthetistReview__data-grid-cell,
.AnaesthetistReview__data-grid-full .AnaesthetistReview__data-grid-cell {
  width: 25%;
}

.AnaesthetistReview__section-footer {
  display: flex;
  flex-flow: row nowrap;
  background: #dcf2f8;
  padding: 10px 0;
  position: relative;
  margin: 20px 0 0;
}

.AnaesthetistReview__section-footer .AnaesthetistReview__data-grid-cell {
  width: 25%;
}

.AnaesthetistReview__data-grid-cell-offset {
  margin-left: 25%;
}

.AnaesthetistReview__data-table {
  width: 100%;
  border: 0;
  padding: 0;
  margin: 0;
  border-collapse: collapse;
}

.AnaesthetistReview__data-table td,
.AnaesthetistReview__data-table th {
  width: 25%;
  padding: 5px 10px 5px 0;
}

.AnaesthetistReview__data-table th {
  text-align: left;
  font-family: 'Open Sans';
  font-weight: 600;
}

.AnaesthetistReview__data-table tbody tr:nth-child(odd) td {
  background: #dcf2f8;
}

.AnaesthetistReview__section-tablist {
  margin: 0 0 15px;
  padding: 0;
  list-style: none;
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: flex-start;
}

.AnaesthetistReview__section-tab {
  padding: 0;
  margin: 0 5px 5px 0;
}

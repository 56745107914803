@import 'Shared/styles/variables';

.category {
  &__add-item {
    &__btn-container {
      display: grid;
      grid-template-columns: 2fr 1fr;
      align-items: center;
      margin: 0 1.5rem 0.5rem 0.5rem;

      h4 {
        font-size: 0.9rem;
        margin-bottom: 0;
        color: $black;
      }

      p {
        margin: 0;
        font-size: 0.9rem;
      }

      :first-child {
        margin-left: 1rem;
      }

      button {
        justify-self: end;
        margin-inline: 1rem;
      }

      &--empty {
        margin-inline: 1.5rem;
        background-color: #f8f8f8;
        padding-block: 1rem;
        border-radius: 0.3em;
      }
    }

    &__form {
      border: 1px solid $light-grey;
      border-radius: 0.25em;
      padding: 1rem;
      margin: 0rem 1.5rem 1rem 1.5rem;
      display: flex;
      align-items: last baseline;
      gap: 0.6rem;
      font-size: 0.9rem;

      label {
        margin-bottom: 0.5rem;
        font-size: 0.9rem;
      }

      #text,
      #value,
      #category {
        width: 100%;
        font-size: 1rem;
      }

      button {
        align-self: last baseline;
        justify-self: center;
      }

      .InlineValidationError {
        font-size: 0.8rem !important;
      }

      .TextInput {
        height: 2.2rem !important;
      }
    }

    &__cancel-button {
      background-color: $white;
      border: none;
      padding: 0;
      text-decoration-line: underline;

      &:hover,
      &:focus {
        color: $font-colour;
        cursor: pointer;
      }
    }
  }
}

@import 'src/Shared/styles/variables';

.Admin_Button_View {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(18%, 1fr));
  gap: 1rem;

  a {
    background-color: white;
    border-radius: 0.25rem;
    padding: 1rem;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
    text-decoration: none;
    color: #005671;
    font-weight: 600;
  }
}

.Admin_Input {
  padding: 10px;
  width: 100%;
  border: 1px solid #aaa;
  border-radius: var(--token-border-radius-xs);
}

.Admin_Error {
  font-weight: 600;
  color: var(--token-color-rag-red);
  padding-top: 0.5rem;
}

.Admin_Warn {
  font-weight: 600;
  color: #ff8a00;
  padding-top: 0.5rem;
}

.Admin_Success {
  font-weight: 600;
  color: #a4c83c;
  padding-top: 0.5rem;
}

.Admin_Table {
  border: 1px solid #aaa;
  border-collapse: collapse;

  th,
  td {
    padding: 0.25rem 1rem;
    text-align: left;
    border: 1px solid #aaa;
  }
}

.Admin__tools {
  margin-top: 3rem;
}

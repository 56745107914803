.input {
  margin-bottom: var(--form-spacing, 10px);
}

.input .input__label {
  box-sizing: border-box;
  padding-right: 5px;
  margin-right: 0;
}

.input input.input__input {
  background: transparent;
  border: none;
  outline: none;
}

.input--center .input__label {
  text-align: right;
}

.input--center input.checkbox {
  width: unset;
  align-self: center;
}

.input--inline input.input__input,
.input--inline .input__data {
  width: 150px;
  text-align: center;
  border-bottom: 1px solid #fff;
  display: inline-block;
}

.input--white .input__input {
  color: #fff;
}

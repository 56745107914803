.Grid {
  display: grid;
  grid-template-columns: 1fr;
  gap: var(--token-spacing-xl);
  --grid-layout-gap: var(--token-spacing-sm);

  @media (min-width: 850px) {
    grid-template-columns: 2fr 1fr;
  }
}

.Action {
  padding: var(--token-spacing-default);
  background: var(--token-color-neutral-100);
  border-radius: var(--token-border-radius-sm);
}

.Alert {
  background: var(--token-color-background-primary-subtle);
  padding: var(--token-spacing-sm) var(--token-spacing-md);
  border-radius: var(--token-border-radius-sm);
}

.ButtonNoMargin {
  margin: 0 !important;
}

.TileMargin {
  margin-top: 36px;
}

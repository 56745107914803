.FileUploader {
  position: relative;

  &.FileUploader--window-dragging::before {
    position: fixed;
    content: '';
    top: 0;
    left: 0;

    width: 100vw;
    width: 100dvw;
    height: 100vh;
    height: 100dvh;
    z-index: 100;
  }

  .FileUpload {
    display: block;
    max-width: 100%;
    margin: 0 auto;
    padding: 2rem 0;

    font-size: 18px;
    color: #005671;

    @media (min-width: 800px) {
      max-width: 800px;
    }
  }

  .FileUpload__start {
    margin-top: 10px;
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: inherit;
  }

  .FileUpload__container {
    display: block;
    width: 100%;
    height: 100%;
    padding: 2rem 1.5rem;
    text-align: center;
    background: #fff;
    border-radius: 5px;
    border: 3px dashed #b1b4b6;
    transition: all 1s ease;
    user-select: none;

    .file-upload-icon {
      fill: #b1b4b6;
      transition: fill 0.5s ease;
    }
  }

  .FileUpload__input:focus + .FileUpload__container,
  .FileUpload__container:hover,
  .FileUpload__container--drag {
    border-color: #0091ff;
    cursor: pointer;

    .file-upload-icon {
      fill: #0091ff;
    }
  }

  .FileUpload__container--drag {
    background-color: #c9e6fb;
  }

  .FileUpload__input:disabled + .FileUpload__container {
    border: none;
    cursor: auto;
  }

  .FileUpload__input {
    opacity: 0;
    position: absolute;
    z-index: -1;
    left: -100vw;
  }

  @media print {
    display: none;
  }
}

.TileGrid {
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: 1fr;
}

.TileGrid .Tile--desktop {
  display: none;
}

.TileGrid .Tile {
  margin-bottom: 1rem;
}

/*
 * TileGrid__column--flush is used to ensure
 * the last tiles within all columns are flush to the bottom.
 * This won't be desired behaviour for all pages.
 */

.TileGrid__column--flush {
  display: flex;
  flex-direction: column;
}

.TileGrid__column--flush .Tile:last-child {
  flex-grow: 1;
}

@supports (display: grid) {
  .TileGrid .Tile {
    margin-bottom: 0;
  }

  .TileGrid__column .Tile {
    margin-bottom: 25px;
  }
}

@media (min-width: 1000px) {
  .TileGrid {
    display: grid;
    grid-gap: 1rem;
    grid-template-columns: repeat(var(--tile-grid-columns, 2), 1fr);
  }

  .TileGrid .Tile--desktop {
    display: inherit;
  }

  .TileGrid .Tile--mobile {
    display: none;
  }
}

@import 'src/Shared/styles/variables';

.Header {
  background: var(--token-color-background-primary-dark);
  margin-left: -16px;
  margin-right: -16px;
  top: 0;
  z-index: 99;

  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 1.5rem;
}

.Header__primary {
  align-items: center;
  flex-basis: 100%;
  gap: 0.5rem;

  @media (min-width: 600px) {
    flex-basis: 70%;
    display: flex;
  }
}

.Header__secondary {
  display: none;
  align-items: center;
  gap: 2rem;

  @media (min-width: 600px) {
    display: flex;
  }
}

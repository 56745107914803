.EpisodeListBadge {
  display: inline-block;
  padding: 3px 7px 2px 7px;
  background: #005671;
  border: none;
  border-radius: 3px;
  color: white;
  font-size: 11px;
  margin-right: 8px;

  margin: 2px 8px 1px 0;
}

.EpisodeListBadge:last-child {
  margin-right: 0;
}

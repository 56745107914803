.RecoveryDiaryOverview__list {
  list-style: none;
  padding: 0;

  max-height: 350px;
  overflow-y: auto;

  @media (min-width: 1000px) {
    max-height: 600px;
  }
}

.RecoveryDiaryOverview__row a {
  display: flex;

  border-top: 1px solid #e5e7eb;
  align-items: center;

  flex-wrap: wrap;
  padding: 15px 10px 10px 0;

  color: inherit;
  cursor: pointer;
  text-decoration: none;
}

.RecoveryDiaryOverview__row a:hover,
.RecoveryDiaryOverview__row a:focus {
  background-color: #f9fafb;
}

.RecoveryDiaryOverview__row--selected {
  background-color: var(--token-color-row-highlight);
}

.RecoveryDiaryOverview__row p {
  padding: 0;
}

.RecoveryDiaryOverview__title {
  margin: 0;
}

.RecoveryDiaryOverview__subtitle {
  color: #555;
  font-size: 12px;
  margin: 0;
}

.RecoveryDiaryOverview__answer-list {
  display: flex;
  list-style: none;
  padding: 0;

  margin-top: 10px;
  flex-wrap: wrap;

  @media (min-width: 500px) {
    margin-left: auto;
    margin-top: 0;
  }
}

.RecoveryDiaryOverview__answer-list li {
  margin-right: 8px;

  &:last-child {
    margin-right: 0;
  }
}

.RecoveryDiaryOverview__clinical-notes {
  font-size: 14px;
  margin-left: 1rem;
  text-align: center;
  align-self: baseline;
}

.RecoveryDiaryOverview__media {
  width: 2.5rem;
  padding-left: 1rem;
  position: relative;
  display: block;
}

.RecoveryDiaryOverview__media_none {
  opacity: 0.3;

  .RecoveryDiaryOverview__media_count {
    display: none;
  }
}

.RecoveryDiaryOverview__media_count {
  position: absolute;
  bottom: -1px;
  right: -5px;
  background-color: var(--token-color-row-highlight);
  font-size: 0.7rem;
  width: 1rem;
  height: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}

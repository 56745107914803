@import '../../styles/variables';

.PrintHeader {
  display: none;
}

@media print {
  .PrintHeader {
    display: initial;
    margin-left: auto;
    margin-right: auto;
    max-width: 1000px;

    h1 {
      color: $font-colour;
      font-weight: 300;
      font-size: 1.4rem;
      letter-spacing: -1px;
    }
  }
}

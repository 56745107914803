@import 'src/Shared/styles/variables';

.ErrorSummary {
  margin-bottom: 24px;

  &:focus {
    border: 0;
    box-shadow: none;
    outline: none;
  }
}

.ErrorSummary__title {
  margin: 0 0 12px;
  font-size: 24px;
}

.ErrorSummary-list {
  margin: 0;
  padding: 0;
  list-style: none;
}

.ErrorSummary-list__item {
  margin-bottom: 6px;
  font-size: 18px;
  font-weight: 600;
  color: var(--token-color-danger-default);

  a {
    color: var(--token-color-danger-default);

    &:visited,
    &:hover,
    &:active {
      color: var(--token-color-danger-default);
    }

    &:hover {
      text-decoration: none;
    }
  }
}

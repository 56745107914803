// Table includes Dynamic Matrix, but not standard matrix.
// Dynamic matrix has remove/add rows and is styled to work
// well on mobile rather than with columns like a standard table or matrix.

.SurveyLayout .sv_container table:not(.sv_q_matrix),
.SurveyTraditionalLayout:not(.SurveyTraditionalLayout--matrix-data-table)
  .sv_container
  table:not(.sv_q_matrix) {
  display: block;
  box-sizing: border-box;
  width: 100%;
  overflow-x: auto;

  *,
  *:before,
  *:after {
    box-sizing: border-box;
  }

  tbody,
  tr,
  td {
    display: block;
    width: 100%;
  }

  thead {
    display: none;
  }

  tbody:not(:empty) {
    border: 1px solid #ccc;
  }

  tbody tr {
    background: #fff;
  }

  tbody tr:nth-child(odd) {
    background: #f0f0f0;
  }

  td,
  th {
    padding: 0.5em;
  }

  .sv_q_mt_label {
    display: block;
  }

  .sv_q_mt_title {
    display: block;
    margin-bottom: 0.5rem;
  }

  // .sv_q_mt_title,
  // .sv_q_mt_item {
  //   width: 100%;
  // }

  td[data-responsive-title],
  th[data-responsive-title] {
    vertical-align: middle;
  }

  @media (max-width: 600px) {
    td[data-responsive-title] {
      text-align: left;
    }
  }

  td {
    &:before {
      content: attr(data-responsive-title);
    }
  }

  tr {
    border-bottom: 1px solid #ddd;
  }

  input,
  select {
    display: block;
    margin-top: 0.1em;
  }

  .sv-action {
    margin-left: auto;
    display: block;
  }
}

.Chapter {
  /* form container */
  .questionnaire {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    max-width: 1140px;
  }

  .triage-questionaire {
    max-width: 1300px;
  }

  .questionnaire--patient {
    max-width: 1140px;
  }

  .UpdateWorkplan {
    display: none;
  }
}

.Chapter--workplan {
  --survey-padding-right: 5px;

  .Chapter__workplan-columns {
    display: grid;
    grid-template-columns: 3.5fr minmax(300px, 1fr);
    position: relative;

    .UpdateWorkplan {
      display: initial;
    }
  }

  .triage-questionaire {
    max-width: 1800px;
  }
}

@import 'src/Shared/styles/_variables';

.procedureHeader {
  h1 {
    margin-bottom: 2rem;
    max-width: 600px;
  }
}

.infoGreen {
  color: #018927;
  font-weight: bold;
}

.stepsList {
  list-style: none;
  counter-reset: item;
  padding: 0;
  margin-top: 1rem;

  h2 {
    font-size: 1.4rem !important;
  }

  > li {
    counter-increment: item;
  }

  > li:not(:last-child) {
    margin-bottom: 1rem;
  }

  h2 {
    display: flex;
    align-items: center;
    word-break: break-word;
  }

  .withoutCounter h2::before {
    display: none;
  }

  h2::before {
    content: counter(item);
    background: var(--token-color-text-heading-default);
    border-radius: 50%;
    color: #fff;
    font-weight: 600;
    width: 2.5rem;
    height: 2.5rem;
    min-width: 2.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1rem;
    margin-right: 1rem;
  }
}

.progressContainer {
  margin-left: 2.5rem;
}

.sharedFiles {
  ol {
    margin: 2rem 0;
    padding-left: 1.25rem;
  }

  .fileSharedOn {
    margin-left: 3rem;
  }
}

.spacer {
  margin-top: 45px;
}

@media (min-width: 1024px) {
  .spacer {
    margin-top: 80px;
  }
}

.ProfileSearch {
  display: inline-block;
  width: 100%;
  align-self: center;

  @media (min-width: 600px) {
    max-width: 400px;
  }
}

.ProfileSearch__text {
  font-size: 0.9rem;
  color: black;
  font-style: italic;
  padding: 0 1rem;
}

@media (min-width: 710px) {
  .ProfileSearch {
    margin: 0;
    vertical-align: text-bottom;
  }
}

.ProfileSearch {
  .react-autosuggest__container {
    margin: 0 auto;
    position: relative;
    z-index: 12;
  }

  .react-autosuggest__input {
    background: #fff;
    padding: 0.75rem;
    padding-left: 2.7rem; /* To allow space for the icon. */
    width: 100%;
    font-size: 1rem;
    box-sizing: border-box;
    border: 1px solid #aaa;
    border-radius: 4px;
    position: relative;
    -webkit-appearance: none;
  }

  .react-autosuggest__input::placeholder {
    color: var(--token-color-text-secondary);
  }

  .react-autosuggest__input--focused,
  .react-autosuggest__input--open {
    border-bottom-color: transparent;
    border-bottom-right-radius: 0 !important;
    border-bottom-left-radius: 0 !important;

    outline-style: solid;
    outline-offset: -4px;
    outline-width: 2px;
  }

  .react-autosuggest__suggestions-container {
    display: none;
    position: absolute;
    opacity: 1;
    width: 200%;
    max-width: 90vw;

    overflow-y: auto;
    max-height: 75vh;

    background-color: #fff;
    border-top-right-radius: 0.5rem;
    border-bottom-left-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
    border: 1px solid #aaa;
    border-top: 0;
    box-shadow: 0 1px 3px 0 rgb(0 0 0 / 10%), 0 1px 2px 0 rgb(0 0 0 / 6%);

    .ProfileSearch__container-info {
      padding: var(--token-spacing-xl) var(--token-spacing-md);
    }

    @media (min-width: 600px) {
      max-width: 50vw;
    }
  }

  .react-autosuggest__suggestions-container:hover,
  input:focus + .react-autosuggest__suggestions-container,
  .react-autosuggest__suggestions-container--open {
    display: block;
  }

  @media (min-width: 950px) {
    .react-autosuggest__suggestions-container {
      max-width: 75vw;
    }
  }

  .react-autosuggest__suggestions-list {
    margin: 0;
    padding: 0;
    list-style-type: none;

    border-top-right-radius: 0.5rem;
  }
}

.ProfileSearch__loader {
  position: absolute;
  top: 0.6rem;
  right: 0.9rem;
  z-index: 12;
}

.ProfileSearch__icon {
  position: absolute;
  top: 0.8rem;
  left: 0.9rem;
  z-index: 12;
}

@import 'src/Shared/styles/variables';

.UnArchiveEpisode {
  width: 42px;
  height: 42px;
  cursor: pointer;
  margin: 6px;
  padding: 0;
  border: 0;
  background: url('../../../../Shared/images/icons/common/icon-unarchive.svg')
    no-repeat;
}

.Admin_CreateHospital {
  padding: 2rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);

  .ButtonLayout {
    margin-top: 0;
  }
}

.Admin_CreateHospital--inputs {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 0 4rem;

  .Admin_CreateHospital--tools {
    h3 {
      color: black;
      font-size: 18px;
      margin-bottom: 1rem;
    }

    .Admin_CreateHospital--tool-links {
      display: flex;
      gap: 1.5rem;

      a {
        font-size: 16px;
      }
    }
  }
}

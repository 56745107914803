.details {
  display: flex;
  gap: 2rem;
  overflow-x: visible;

  span {
    display: block;
    font-weight: 600;
    width: 100%;
    margin-bottom: 0.25rem;
  }

  div {
    word-break: break-word;
    flex-shrink: 0;
    max-width: 100%;
  }

  & + details {
    margin-top: 2rem;

    summary {
      cursor: pointer;
    }
  }

  details & {
    margin-top: 1rem;
    flex-wrap: wrap;
  }

  summary:focus-visible {
    outline: none;
  }
}

.detailLabel {
  font-weight: 600;
  font-size: 1.15rem;
}

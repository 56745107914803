@import 'src/Shared/styles/variables';

.RecoveryList_FilterReportedConcerns,
.RecoveryList_FilterClinicalNotes {
  margin-bottom: 1rem;

  .RecoveryList_FilterReportedConcerns_Title {
    margin-bottom: 0.25rem;
  }

  p {
    max-width: 220px;
  }

  button {
    padding: 0;
    margin-right: 1rem;
    border: 2px solid rgba(0, 0, 0, 0.01);
    width: 220px;
    display: inline-block;
    text-align: left;
    cursor: pointer;
    position: relative;
    border-radius: 0.5rem;
    margin-bottom: 1rem;

    &.RecoveryList_FilterReportedConcerns_Active,
    &.Active {
      .Tile {
        background-color: var(--token-color-row-highlight);
      }
      border-color: #3b82f6;
    }

    .RecoveryList_FilterBy_Icon {
      position: absolute;
      top: 0.5rem;
      right: 0.5rem;
      width: 22px;
      height: 22px;
      border-radius: 50%;

      &.RED {
        background-color: var(--token-color-red-700);
      }

      &.AMBER {
        background-color: #ff8a00;
      }

      &.GREEN {
        background-color: #a4c83c;
      }
    }

    .RecoveryList_FilterBy {
      color: #555;
      font-size: 0.75rem;
      margin-bottom: 0.4rem;
    }

    .Tile {
      margin: 0;
      padding: 1.25rem 1.5rem 1.25rem 0.75rem;
      border-radius: inherit;
    }
  }

  .RecoveryList_FilterClinicalNotes_button {
    width: 100%;
    margin-bottom: 0.75rem;
  }
}

.RecoveryList_FilterClinicalNotes {
  margin-bottom: 0;
}

.RecoveryList_Concern {
  padding: 0.3rem;
  text-align: center;
  width: 100px;
  font-size: 12px;

  &.RecoveryList_Concern--RED {
    background-color: #ea9999;
    border: 2px solid var(--token-color-red-700);
    color: #fff;
  }

  &.RecoveryList_Concern--AMBER {
    background-color: #f9cb9c;
    border: 2px solid #ff8a00;
  }

  &.RecoveryList_Concern--GREEN {
    border: 2px solid #a4c83c;
  }
}

.RecoveryList__procedure-name,
.RecoveryList__patient-name {
  // Ideally truncation would be a prop of the Typography component
  // but we would need to manage max-width.
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  max-width: 200px;
}

.RecoveryList__createdAt {
  font-size: 0.7rem;
}

.AuditWorkplanExpandedRow {
  --tile-padding: 0 1rem 1.5rem 1.5rem;

  .sv_p_root {
    display: grid;
    gap: 2rem;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  }

  .TileView {
    padding: 1rem;
  }

  .Tile {
    margin-bottom: 0;
  }
}

@media (max-width: 1000px) {
  .AuditWorkplanExpandedRow .Tile {
    max-width: 80vw;
  }
}

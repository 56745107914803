.RecoveryDiaryVisualisation__header {
  text-align: right;
}

.RecoveryDiaryVisualisation__header h2 {
  display: inline-block;
  padding-right: 15px;
}

.RecoveryDiaryVisualisation__header select {
  display: inline-block;
  padding: 5px;
}

.RecoveryDiaryVisualisation {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 25px;

  max-width: 900px;

  @media (min-width: 400px) {
    grid-template-columns: 1fr 1fr;
  }

  @media (min-width: 800px) {
    grid-template-columns: 1fr 1fr 1fr;
  }
}

.RecoveryDiaryVisualisation svg {
  width: 100%;
  height: 100%;
}

.RecoveryDiaryVisualisation .Tile {
  height: 225px;
  max-height: 225px;
  padding: 0;
  display: flex;
  flex-direction: column;
}

.RecoveryDiaryVisualisation h2 {
  margin: 0;
  padding: 1rem 1rem 0 1rem;
}

.RecoveryDiaryVisualisation .Tile__graph {
  height: 66.66%;
  margin-top: auto;
}

.RecoveryDiaryVisualisation .Tile__graph--positive-down {
  background-image: linear-gradient(
    rgba(255, 0, 0, 0.15) 0%,
    rgba(255, 169, 0, 0.15) 40%,
    rgba(243, 255, 0, 0.15) 60%,
    rgba(0, 255, 1, 0.15) 100%
  );
}

.RecoveryDiaryVisualisation .Tile__graph--positive-up {
  background-image: linear-gradient(
    0deg,
    rgba(255, 0, 0, 0.15) 0%,
    rgba(255, 169, 0, 0.15) 40%,
    rgba(243, 255, 0, 0.15) 60%,
    rgba(0, 255, 1, 0.15) 100%
  );
}

.RecoveryDiaryVisualisation circle.visx-circle-glyph {
  r: 3;
}

.UpdateWorkplan,
.FilterWorkplan,
.AuditWorkplanExpandedRow {
  .sv_p_title_expandable {
    padding: 0;
    margin-bottom: 0;
    margin-top: 1.5rem;
    outline: none;
  }

  .sv_p_title_expandable:focus-visible {
    background-color: #f4f4f4;
  }

  .sv_p_container .sv_q_title {
    margin: 0;
  }

  .sv_p_container .sv_row:first-child .sv_q_title {
    display: none;
  }

  .sv_q_title[aria-label='Other On Hold Reasons'] {
    display: none;
  }

  .sv_q_checkbox {
    margin: 1rem 0;
  }

  .sv_q_matrix_dynamic {
    border-collapse: collapse;
  }

  .sv_p_title_expandable {
    cursor: pointer;
    position: relative;
  }

  // Taken from previous version of SurveyJS which they have since removed.
  .sv_p_title_expandable::after {
    content: '';
    display: block;
    background-image: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='utf-8'%3F%3E%3C!-- Generator: Adobe Illustrator 21.1.0, SVG Export Plug-In . SVG Version: 6.00 Build 0) --%3E%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 10 10' style='enable-background:new 0 0 10 10;' xml:space='preserve'%3E%3Cstyle type='text/css'%3E .st0%7Bfill:%23404040;%7D%0A%3C/style%3E%3Cpolygon class='st0' points='2,2 0,4 5,9 10,4 8,2 5,5 '/%3E%3C/svg%3E%0A");
    background-position-x: center;
    background-position-y: right;
    background-repeat: no-repeat;
    background-size: 10px 12px;
    width: 24px;
    height: 24px;
    position: absolute;
    right: 0px;
    top: 0;
  }

  fieldset {
    overflow-y: hidden;

    .sv_q_checkbox {
      margin-top: 1rem;
    }
  }

  .sv_qstn {
    overflow-y: hidden;
  }

  .sv_row,
  div {
    min-width: 0 !important;
  }
}

.GroupHeader {
  border-bottom: 1px solid var(--token-color-neutral-200);
  margin: 0;

  padding: var(--token-spacing-lg) var(--token-spacing-2xl);
}

.GroupBody {
  padding: var(--token-spacing-xl) var(--token-spacing-2xl)
    var(--token-spacing-3xl) var(--token-spacing-2xl);
}

.FieldGroup {
  all: unset;
  display: block;
}

html {
  scroll-padding-top: 170px;
}

.app-container {
  font-family: 'Open Sans';
  line-height: 1.2;
  font-weight: normal;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  padding-left: var(--token-spacing-default);
  padding-right: var(--token-spacing-default);
}

@media print {
  .app-container {
    display: block; /* page breaks don't work on flex */
  }

  textarea {
    background: transparent;
    border: none;
    color: #000;
    resize: none !important;
  }
}

/* Hide amplify-ui error messages as we're displaying our own */

.amplify-error-section {
  display: none;
}

.Link {
  appearance: none;
  background: none;
  border: none;
  color: #555;
  cursor: pointer;
  text-decoration: underline;
  padding: 0;
}

.Link:focus:not(:focus-visible) {
  outline: none;
}

.Link:hover {
  text-decoration: none;
}

.link {
  cursor: pointer;
  color: #00546b;
  text-transform: uppercase;
  font-size: 12px;
  text-decoration: none;
}

.link:hover {
  text-decoration: underline;
}

.link--icon {
  vertical-align: bottom;
}

.link--icon .icon {
  box-sizing: border-box;
  max-width: 100%;
  vertical-align: bottom;
}

.link--icon + .link--icon {
  margin-left: 5px;
}

.icon {
  width: 28px;
  height: 28px;
  border-radius: 50%;
}

.icon-think {
  width: 24px;
  height: 24px;
  border-radius: 50%;
}

.icon--accept {
  padding: 6px;
  background-color: green;
}

.icon--cancel {
  padding: 6px;
  background-color: red;
}

.hidden {
  display: none !important;
}

/* Use the <VisuallyHidden/> component if you can rather than this class selector */
.visuallyhidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  white-space: nowrap;
}

.error {
  color: #e30303;
}

.centered {
  text-align: center;
}

.form-control__label {
  cursor: pointer;
}

textPath {
  letter-spacing: 0.07em;
  font-size: 0.95em;
}

strong {
  font-family: 'Open Sans';
  font-weight: 600;
}

.main-content {
  flex: 1;
}

/**
 TODO: This is far from ideal.
 We need to ensure that the site can be full height (sticky footer) 
 without specific overrides.
*/
.main-content:has(> .TileView),
.main-content:has(> .ContentPage) {
  display: flex;
  flex-direction: column;

  > .TileView,
  > .ContentPage {
    flex: 1;
  }
}

.main-content:focus {
  border: 0;
  box-shadow: none;
  outline: none;
}

fieldset {
  border: 0;
  margin: 0;
  padding: 0;
  background: none;
  all: inherit;
}

/**
 * Workaround for CRA bug: https://github.com/facebook/create-react-app/issues/11771
 */
body
  > iframe[style*='2147483647']:not([id='webpack-dev-server-client-overlay']) {
  display: none;
}

hr {
  border: none;
  background: var(--token-color-border-default);
  height: 1px;
}

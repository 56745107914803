.ClinicalNotes__outer,
.ClinicalNotes__inner {
  list-style: none;
  padding: 0;
}
.ClinicalNotes__outer p,
.ClinicalNotes__inner p {
  white-space: pre-wrap;
}

.ClinicalNotes__inner {
  max-width: 80ch;
  margin: 0 auto;
}

.ClinicalNote {
  border-bottom: 1px solid #e5e7eb;
  border-top: 1px solid #e5e7eb;
  padding-bottom: 1.5rem;
  padding-top: 1.5rem;
}

.ClinicalNote:first-child {
  border-top: none;
}

.ClinicalNote:last-child {
  border-bottom: none;
}

.ClinicalNote__header {
  display: flex;
  align-items: baseline;
}

.ClinicalNote__name {
  font-weight: 600;
  margin-right: 0.5rem;
}

.ClinicalNote__time {
  color: #333;
  font-size: 0.8rem;
  margin-right: auto;
}

.ClinicalNote__text {
  color: #222;
  line-height: 1.4;
}

.ClinicalNotes__heading {
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  margin-bottom: 2rem;
}

.ClinicalNotes--overview .ClinicalNote__text {
  -webkit-line-clamp: 3;
}

.ClinicalNotes--overview li {
  padding: 0;
  border: none;
  margin: 1.2rem 0;
}

.ClinicalNotes--overview .ClinicalNote__text {
  margin: 0;
  margin-top: 0.5rem;
}

.ClinicalNotes--overview .ClinicalNote__read-more {
  font-size: 14px;
  font-weight: 600;
}

.ClinicalNotes--overview .ClinicalNotes__view-all {
  margin-left: 1rem;
}

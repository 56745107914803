@import 'src/Shared/styles/variables';

.BackLink {
  color: #fff;
  font-size: 18px;
  position: relative;
  padding-left: 12px;
  display: block;
  margin: 0 6px 6px 0;
  background: none;
  border: none;
  text-decoration: underline;
  cursor: pointer;
  outline: none;

  &:visited,
  &:hover,
  &:active {
    color: #fff;
    text-decoration: none;
  }

  &:hover {
    text-decoration: none;
  }

  // A snazzy way of creating the chevron - kudos to the GOV design system for this
  &::before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 3px;
    width: 7px;
    height: 7px;
    margin: auto 0;
    transform: rotate(225deg);
    border: solid;
    border-width: 2px 2px 0 0;
  }
}

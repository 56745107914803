@import 'src/Shared/styles/variables';

.ArchiveEpisode {
  margin-top: 20px;
}

.ArchiveEpisode__other-description .input__input {
  border: 1px solid $font-colour !important; // TODO tidy this up once the style issues in <Input /> have been addressed
  width: 90%;
  margin-left: 14px;
  line-height: 1.4;
}

.archive-reason-title {
  margin-bottom: 1rem;
}

.UserDetails {
  text-align: center;
  align-items: center;
  display: flex;
  flex-direction: column;
}

.UserMenu {
  padding: 2rem; // Replace with tokens once bug fixed. https://github.com/tokens-studio/sd-transforms/issues/168

  hr {
    border: none;
    background: var(--token-color-border-default);
    height: 1px;
    margin: var(--token-spacing-sm) 0;
  }

  ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  li {
    padding: var(--token-spacing-xs) 0;
    width: 100%;
  }
}

.HideOnLarge {
  @media (min-width: 600px) {
    display: none;
  }
}

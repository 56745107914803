.Privacy {
  font-family: 'Open Sans';
  color: rgb(8, 66, 88);
  margin: 70px 0;
  background: white;
  width: 100%;
  text-align: center;
  padding: 50px 0;
  font-size: 18px;
}

.Privacy__title {
  color: rgb(19, 72, 95);
  margin-bottom: 30px;
}

.Privacy .form-control__label {
  position: relative;
  display: inline;
  margin-left: 5px;
  text-transform: none;
  font-weight: 600;
}

.Privacy__list {
  list-style-position: inside;
  max-width: 550px;
  text-align: left;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 3rem;
  padding-left: 0;
}

.Privacy__list-item {
  margin-bottom: 20px;
}

.Privacy .link {
  display: block;
  margin-bottom: 20px;
  font-size: 11px;
}

.Privacy__fullPolicy {
  margin: 2.5rem 0 2rem 0;
}

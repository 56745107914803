.cb {
  padding: var(--token-spacing-md);
  border: 1px solid #e0e0e0;
  border-radius: var(--token-border-radius-sm);
  margin-top: 8px;
  display: flex;
  align-items: flex-start;
  gap: 10px;
}

.cb:hover:not(.isChecked) {
  border: 1px solid #6c6c6c;
}

div.isChecked {
  border: 3px solid var(--token-color-border-primary-default);
}

.cb_checkbox {
  margin-top: var(--token-spacing-2xs);
}

.cb_content {
  margin-bottom: var(--token-spacing-xs);
}

.cb_content p {
  margin-top: 5px;
}

@import 'src/Shared/styles/variables';

.Admin_UpdatePermissions .Callout {
  margin-bottom: 1.25rem;
}

.Admin_UpdatePermissions input[type='checkbox'] {
  margin-right: 0.25rem;
}

.Admin_UpdatePermissions__input-row {
  margin-bottom: 1.5rem;
}

.Admin_UpdatePermissions select {
  padding: 0.5rem;
}

// https://css-tricks.com/an-auto-filling-css-grid-with-max-columns/

:root {
  --grid-layout-gap: var(--token-spacing-lg);
}

.Grid {
  --grid-item--min-width: 0;

  --gap-count: calc(var(--columns-count) - 1);
  --total-gap-width: calc(var(--gap-count) * var(--grid-layout-gap));
  --grid-item--max-width: calc(
    (100% - var(--total-gap-width)) / var(--columns-count)
  );

  display: grid;
  grid-template-columns: repeat(
    auto-fill,
    minmax(max(var(--grid-item--min-width), var(--grid-item--max-width)), 1fr)
  );
  gap: var(--grid-layout-gap);
}

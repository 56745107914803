.ExpandedComponent {
  --tile-padding: 1.5rem;
  background-color: var(--token-color-background-secondary-subtle);
}

.ExpandedComponent__status {
  display: flex;
}

.ExpandedComponent__status-date {
  font-size: 0.8rem;
  margin-left: auto;
}

@media (max-width: 1000px) {
  .ExpandedComponent .TileGrid {
    width: 80vw;
  }
}

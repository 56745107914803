@import 'src/Shared/styles/variables';

.icon-btn {
  border: none;
  padding: 0.3em 0.6em;
  display: flex;
  gap: 0.3rem;
  background-color: $white;
  font-size: 0.85rem;

  &__icon {
    display: flex;
    align-self: center;
  }

  path {
    stroke: $black !important;
  }

  &:hover,
  &:focus {
    text-decoration-line: underline;
    cursor: pointer;
  }
}

.delete-btn {
  &:hover,
  &:focus {
    color: var(--token-color-danger-default);
    text-decoration-color: var(--token-color-danger-default);

    path {
      stroke: var(--token-color-danger-default) !important;
    }
  }
}

.hide-on-blur {
  path {
    stroke: $white !important;
  }

  color: $white;
}

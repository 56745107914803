.alert {
  &__contents {
    display: flex;
    align-items: center;
    gap: 1rem;

    &__button {
      margin-top: 0 !important;
    }

    &--loading {
      justify-content: left;
      gap: 0.5rem;

      .Loader {
        border: 2px solid #00556e;
        border-left-color: transparent;
      }
    }

    &--success {
      span {
        font-weight: bold;
      }
    }
  }
}

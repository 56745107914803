@import 'src/Shared/styles/variables';

.panelTile {
  padding: 2.5rem;
  position: relative;
  margin-bottom: 1.5rem;

  &:last-child {
    margin-bottom: 0;
  }

  p,
  li {
    font-size: 1.1rem;
    margin-bottom: 0.75rem;
  }

  p {
    max-width: 960px;
  }

  .title {
    color: var(--token-color-text-heading-default);
    font-weight: 600;
    text-transform: uppercase;
    margin: 0 0 2rem 0;
    font-size: 1.25rem;
  }

  h3 {
    color: var(--token-color-text-heading-default);
    font-weight: 700;
    font-size: 1.25rem;
  }

  h4 {
    border-left: 4px solid var(--token-color-text-heading-default);
    padding: 0.25rem 1rem;
    font-weight: 600;
    line-height: 1.7;
  }

  .inactive {
    background-color: #efefef;
    border-radius: 0.25rem;
    padding: 1.5rem;
  }

  hr {
    border: 0;
    border-top: 1px solid #c4c4c4;
    margin: 2rem 0;
  }

  ul {
    list-style-type: square;
    margin: 1.5rem 0;

    li {
      padding-left: 1rem;
    }

    li::marker {
      color: var(--token-color-text-heading-default);
      font-size: 1.2rem;
    }

    a {
      margin-top: 1.25rem;
      margin-left: -1rem;
    }
  }
}

.panelInactive {
  .title {
    color: $black;
  }

  ul li::marker {
    color: $black;
    font-size: 1rem;
  }
}

@media (max-width: 767px) {
  .tile,
  .procedureTitle {
    padding-left: 0.75rem !important;
    padding-right: 0.75rem !important;
  }
}

.panelHidePadding {
  padding: 0;
}

.panelFullWidth {
  grid-column: span 2;
}

.panelClose {
  position: absolute;
  top: 5px;
  right: 10px;
  color: white;
  background-color: $dark-grey;
  width: 30px;
  height: 30px;
  font-size: 20px;
  border-radius: 50%;
  cursor: pointer;
  border: none;
  appearance: none;
  padding-bottom: 5px;
  z-index: 2;
  display: none;

  @media (min-width: 1000px) {
    display: block;
  }

  @media (min-width: 800px) {
    top: -12px;
    right: -12px;
  }
}

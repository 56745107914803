@import 'src/Shared/styles/variables';

.SkipLink {
  position: absolute;
  left: -9999em;

  &:focus {
    z-index: 2;
    left: 0;
    top: 0;
    width: 100%;
    padding: 12px;
    color: $black;
    outline: none;
    background-color: $skip-link;
  }
}

dl.DescriptionList {
  div {
    display: flex;
    margin-bottom: 5px;
  }

  dt {
    font-weight: 600;
  }

  dd {
    margin-left: 5px;
  }
}

dl.DescriptionList--horizontal {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem 2rem;
  margin: 0;

  div {
    display: block;
  }

  dd {
    margin-top: 5px;
    margin-left: 0;
  }
}

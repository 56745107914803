@import 'src/Shared/styles/variables';

.Form {
  color: $black;
  --form-spacing: 1.5rem;
}

.Form-link {
  display: inline-block;
  color: $black;

  &:visited,
  &:hover,
  &:active {
    color: $black;
  }

  &:hover {
    text-decoration: none;
  }
}

.Form-button-as-link {
  background: none;
  border: none;
  padding: 0;
  color: $black;
  text-decoration: underline;
  cursor: pointer;

  &:focus {
    border: 0;
    box-shadow: none;
    outline: none;
  }
}

.Form-button-as-link--white {
  color: $white;
}

.Form-label {
  display: block;
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 2px;
  vertical-align: text-bottom;
}

.Form-label--small {
  font-size: 1rem;
}

.Form-control {
  margin-bottom: var(--form-spacing, 24px);
}

.Form-control--with-error {
  .TextInput {
    border: 2px solid var(--token-color-danger-default);
  }
}

.Form-select {
  display: block;
  margin: 1rem 0;
  min-width: 150px;
}

.Form-hint {
  color: #555;
  margin-bottom: 0.3rem;
}

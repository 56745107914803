.MeasureInput__input {
  padding: 0.5rem;
  margin-right: 0.5rem;
  width: 3.75rem !important;
}

.MeasureInput__unit {
  margin-right: 1.75rem;
  font-weight: 600;
  font-family: 'Open Sans';
}

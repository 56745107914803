.HospitalRecoveryEntry {
  .sv_container {
    border: none;
    background: none;
    padding: 0;
  }

  .sv_qstn {
    color: #005671;
    padding: 0;
    overflow: visible;
  }

  .sv_container .sv_row:before {
    display: none;
  }

  .sv_p_container {
    border-top: 1px solid #e5e7eb;
    padding: 3rem 0;
  }

  .sv_header {
    display: none;
  }

  .sv_q_required_text {
    display: none;
  }

  .sv_qstn fieldset {
    text-align: center;
  }

  .sv_qstn textarea[disabled] {
    background: #fff;
    color: #000;
    padding: 1rem;
    line-height: 1.3;

    height: 250px;
    width: 100% !important;
    resize: vertical;

    border: 1px solid #ccc;
    border-radius: 0;
    box-shadow: none;
    opacity: 1;
    appearance: none;
  }

  .sv_qstn .sv_q_radiogroup {
    width: initial;
    padding-right: 2em;
  }

  .sv_qstn .sv_q_radiogroup:last-child {
    padding-right: 0;
  }

  .sv_q_title {
    display: flex;
    font-weight: 600;
    color: #005671;
    font-size: 1rem;
    margin-top: 0;
  }

  .AnswerCategory {
    margin-right: 12px;
  }

  .sv_row + .sv_row {
    margin-top: 1rem;
  }

  .sv_p_description {
    display: flex;
    font-weight: 600;
    color: #005671;
    font-size: 1.1rem;
    padding-left: 0 !important;
    margin-bottom: 1.2rem;
  }
}

.HospitalRecoveryEntry__clinical-notes-actions a + a {
  margin-left: 0.5rem;
}

.HospitalRecoveryEntry .sv_q_description {
  display: none;
}

@media print {
  html,
  body {
    font-size: 12px !important;
  }

  .Header {
    display: none;
  }

  .hidden-on-print {
    display: none;
  }

  .PatientProfileSummary {
    display: none;
  }

  .Breadcrumb {
    display: none;
  }

  .PageHeader {
    display: none;
  }

  footer {
    display: none;
  }

  .PoaSummary__main {
    padding: 0;
    border: none;
  }

  .PoaSummary {
    padding: 0;
    margin: 0;

    /* Force sections with background colours to print background 
       Potentially not great as overrides user choice.
       */
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact;

    textarea,
    input {
      display: none !important;
    }

    #section-content-episode-poa-hq {
      textarea,
      input {
        display: inherit !important;
      }
    }

    .PoaSummary__ASA-grade-entry {
      display: none;
    }
  }
}

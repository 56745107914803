.proceduresListHeader h2 {
  font-size: 1.5rem;
}

.proceduresListDetails {
  margin-bottom: 2rem;

  span {
    margin-right: 0.5rem;
  }
}

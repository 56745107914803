.DateInput {
  input {
    border: none;
  }

  .react-datepicker__tab-loop {
    display: none; // Hide Date Picker
  }
}

.DateInput--show-picker .react-datepicker__tab-loop {
  display: block;
}

.DateInput--show-picker .react-datepicker__triangle {
  left: -90% !important;
}

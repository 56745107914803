.MonthDatePicker {
  .react-datepicker__header {
    padding-bottom: 6px;
  }

  .react-datepicker__month-text {
    width: 5rem;
    padding: 5px;
  }

  .react-datepicker-wrapper,
  input[type='text'] {
    width: 100%;
    @media (min-width: 500px) {
      width: auto;
    }
  }
}

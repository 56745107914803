:root {
  --tile-padding: 1rem;
}

.Tile {
  background-color: rgb(255, 255, 255);
  border-radius: 0.5rem;
  padding: 1rem;
  padding: var(--tile-padding);
  box-shadow:
    0 1px 3px 0 rgba(0, 0, 0, 0.1),
    0 1px 2px 0 rgba(0, 0, 0, 0.06);
  margin-bottom: 25px;
}

.Tile--elevation-raised {
  box-shadow: var(--token-elevation-raised-shadow);
}

.Tile--elevation-overlay {
  box-shadow: var(--token-elevation-overlay-shadow);
}

.Tile--elevation-subtle {
  box-shadow: var(--token-elevation-subtle-shadow);
}

@media (max-width: 599px) {
  // This matches the behaviour of <Card /> too.
  .Tile--ResponsiveFullWidth {
    border-radius: 0;
    padding-left: var(--token-spacing-md);
    padding-right: var(--token-spacing-md);
    margin-left: calc(var(--token-spacing-md) * -1);
    margin-right: calc(var(--token-spacing-md) * -1);
  }
}

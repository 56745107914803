.OnHoldStateTransition {
  text-align: center;
  margin: 0 auto;
  max-width: 600px;

  h2 {
    color: #000;
  }

  .Link {
    color: #000;
    font-weight: 600;
    text-decoration: none;
    font-size: 0.9rem;

    &:hover,
    &:focus {
      text-decoration: underline;
    }
  }
}

.OnHoldStateTransition__cta,
.OnHoldStateTransition__confirm {
  font-size: 1.25rem;
  font-weight: 600;
  max-width: 500px;
  margin: 0 auto;
  line-height: 1.3;
  margin-top: 2rem;
}

.OnHoldStateTransition__confirm {
  font-style: italic;
  max-width: 500px;
  margin: 3rem auto;
  font-size: 1.2rem;
}

.OnHoldStateTransition__container {
  max-width: 900px;
  margin: 0 auto;
}

.OnHoldStateTransition__note {
  max-width: 450px;
  font-style: italic;
  text-align: left;
  margin: 2rem auto;
}

.CodingCard {
  background-color: #fff;
  border-top: 1px solid var(--token-color-border-default);

  /* Undo Padding set in TileView */
  margin-left: calc(-1 * var(--token-spacing-sm));
  margin-right: calc(-1 * var(--token-spacing-sm));

  padding: var(--token-spacing-inset-md);

  &:last-child {
    border-bottom: 1px solid var(--token-color-border-default);
  }
}

.CodingCard__billable {
  height: 100%;
  margin-left: auto;
}

@import 'src/Shared/styles/variables';

.Footer {
  background: var(--token-color-background-primary-dark);
  margin-left: -16px;
  margin-right: -16px;

  color: $white;
  font-size: 14px;
  text-align: center;

  padding: 1rem 1.5rem;
}

.FooterLinks {
  display: inline-block;
}

.FooterList {
  margin: 0;
  padding: 0;
  list-style: none;
}

.FooterListItem {
  margin-left: 12px;
  display: inline-block;

  a {
    color: $white;

    &:hover {
      text-decoration: none;
    }
  }
}

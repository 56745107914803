.CookieBanner {
  background: #fff;
  margin-left: -1rem;
  margin-right: -1rem;
  padding: 1rem;
}

.CookieBanner h2 {
  font-size: 1.2rem;
}

.CookieBanner__message {
  margin: 0;
  margin-bottom: 1.5rem;
}

.CookieBanner__actions {
  min-width: 100px;
  text-align: right;
  width: 100%;
}

.CookieBanner__actions button {
  margin-left: 0 !important;
  width: 100%;
}

@media (min-width: 600px) {
  .CookieBanner h2 {
    font-size: 1.5rem;
  }

  .CookieBanner__actions button {
    margin-left: 1.5rem !important;
    width: auto;
  }
}

.CookieBanner__wrapper {
  max-width: 800px;
  margin: 0 auto;
}

.CookieBanner {
  max-height: 500px;
}

.CookieBanner--exit {
  overflow: hidden;
  transition: max-height 0.4s, padding 0.6s;
  max-height: 0;
  padding: 0;
}

// TODO: Use Alert instead but design may need updating for it.
.Info {
  border-radius: var(--token-border-radius-sm);
  border: 2px solid var(--token-color-blue-900);
  background: var(--token-color-blue-100);
  padding: var(--token-spacing-inset-stretch-default);
}

.Grid {
  --grid-layout-gap: var(--token-spacing-sm);
}

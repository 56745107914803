.blood-pressure {
  flex-flow: row;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
}

.blood-pressure input {
  width: 50px;
  flex-grow: 0 !important;
}

@import 'src/Shared/styles/variables';

.OverflowChecklist,
.FilterWorkplan .sv_row,
.UpdateWorkplan .sv_row,
.AuditWorkplanExpandedRow .sv_row {
  max-width: 250px;

  ul {
    list-style: none;
    padding: 0;
    width: 100%;
  }

  li {
    display: flex;
    align-items: baseline;
    margin-bottom: 0.5rem;
  }

  input {
    margin-right: 0.5rem;
  }

  label {
    color: var(--token-color-text-heading-default);
  }

  select {
    width: 100%;
  }

  .OverflowChecklist__dropdown {
    display: flex;
    align-items: center;
    gap: 1rem;
  }
}

.TrainingBanner {
  background-color: var(--token-color-olive-300);
  padding: var(--token-spacing-xs) 0;
  margin-left: calc(-1 * var(--token-spacing-md));
  margin-right: calc(-1 * var(--token-spacing-md));
}

.top {
  border-bottom: 1px solid var(--token-color-olive-400);
}

.bottom {
  border-top: 1px solid var(--token-color-olive-400);
}

@import 'src/Shared/styles/variables';

.Breadcrumb {
  margin: 0;
  padding: 0 var(--token-spacing-default);
}

.BreadcrumbItem {
  display: flex;
  align-items: center;
}

.defaultView {
  display: none;
  svg {
    color: var(--token-color-neutral-500)
  }
}

.mobileView {
  display: flex;
  svg {
    margin-right: 3px;
  }
}

@media (min-width: 600px) {
  .defaultView {
    display: flex;
  }

  .mobileView {
    display: none;
  }
}

.TileView {
  padding: 2rem 1rem;
  box-sizing: inherit;

  min-height: 70vh;

  .ExpandedComponent &,
  .AuditWorkplanExpandedRow & {
    min-height: initial;
    margin: 0;
  }

  *,
  *:before,
  *:after {
    box-sizing: inherit;
  }
}

.TileView h1,
.TileView h2,
.TileView h3,
.TileView h4 {
  color: #005671;
  font-weight: 600;
  margin-top: 0;
  margin-bottom: 1.2rem;
  line-height: 1.3;
}

.TileView h1 {
  font-size: 1.5rem;
  line-height: 2rem;
}

.TileView h2 {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.TileView h3 {
  font-size: 1.1rem;
  line-height: 1.5rem;
}

.TileView .BackLink {
  color: #000;
  font-size: 14px;
}

.TileView .BackLink::before {
  border-color: #000;
  border-width: 1px 1px 0 0;

  height: 5px;
  width: 5px;
}

@media (max-width: 767px) {
  .TileView {
    padding-left: var(--token-spacing-sm);
    padding-right: var(--token-spacing-sm);
  }
}

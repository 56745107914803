// TODO: rewrite/browser test

.widget_vasSlider {
  // colors, vars, mixins
  $slider-base: rgba(0, 0, 0, 0.2);
  $slider-ticks: rgba(#fff, 1);
  $slider-error: #fc3770;
  $slider-warn: #ff7f36;
  $slider-info: #f6d866;
  $slider-debug: #e1e880;
  $slider-trace: #0091ff;

  $radius: 1em;

  @mixin slider-track() {
    margin: 0 -0.5em;
    cursor: pointer;
  }
  @mixin slider-thumb() {
    font-size: 1em;
    box-shadow: 0 0 0 0.15em rgba(#000, 0.2) inset, 0 0 0 0.15em #fff;
    height: 1.5em;
    width: 1.5em;
    border-radius: $radius;
    background: $slider-base;
    cursor: pointer;
  }

  .context-labels {
    display: flex;
    margin: 1em 0;
    padding: 0;
    justify-content: space-between;
    li {
      list-style: none;
    }
  }

  // slider fun
  .heat-slider {
    position: relative;
    font-size: 1em;
    line-height: 0;
    &:before,
    &:after {
      pointer-events: none;
      content: '';
      position: absolute;
      top: 0;
      right: 1em;
      bottom: 0;
      left: 0;
      z-index: 1;
    }
    &:before {
      background: $slider-base;
      border-radius: $radius;
    }

    .heat-bar {
      position: absolute;
      top: 0;
      right: 1em;
      bottom: 0;
      left: 0;
      width: auto;
      z-index: 1;
      border-radius: $radius;
      background-image: repeating-linear-gradient(
        to right,
        $slider-trace,
        $slider-debug,
        $slider-info,
        $slider-warn,
        $slider-error
      );
    }
    input {
      position: relative;
      appearance: none;
      -webkit-appearance: none;

      padding: 0;
      margin: 0 0 0 calc(0% - 0.5em);
      width: calc(100% - 0.5em);
      height: 2em;
      background: none;
      z-index: 2;
      box-sizing: border-box;
      font-size: 1em;

      // slider track
      &::-webkit-slider-runnable-track {
        @include slider-track();
        //background: transparent;
        -webkit-appearance: none;
      }

      &::-moz-range-track {
        @include slider-track();
        background: transparent;
      }

      &::-ms-track {
        @include slider-track();
        background: transparent;
        border-color: transparent;
        color: transparent;
        border-width: 0;
      }
      &::-ms-fill-lower {
        background: transparent;
      }
      &::-ms-fill-upper {
        background: transparent;
      }

      // slider thumb
      &::-webkit-slider-thumb {
        @include slider-thumb();
        -webkit-appearance: none;
      }
      &::-moz-range-thumb {
        @include slider-thumb();
      }
      &::-ms-thumb {
        @include slider-thumb();
      }
      &:focus {
        outline: none;
        &::-webkit-slider-thumb {
          box-shadow: 0 0 0 0.15em rgba(#000, 0.2) inset, 0 0 0 0.15em #fff,
            0 0 1em;
        }
        &::-moz-range-thumb {
          box-shadow: 0 0 0 0.15em rgba(#000, 0.2) inset, 0 0 0 0.15em #fff,
            0 0 1em;
        }
        &::-ms-thumb {
          box-shadow: 0 0 0 0.15em rgba(#000, 0.2) inset, 0 0 0 0.15em #fff,
            0 0 1em;
        }
      }

      &::-moz-focus-outer {
        border: 0;
      }

      &.no-value {
        &::-webkit-slider-thumb {
          opacity: 0;
        }
        &::-moz-range-thumb {
          opacity: 0;
        }
        &::-ms-thumb {
          opacity: 0;
        }
      }
    }
  }
}

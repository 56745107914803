@import 'src/Shared/styles/variables';

.Header {
  background: var(--token-color-background-primary-dark);
  margin-left: -16px;
  margin-right: -16px;
  top: 0;
  z-index: 70;

  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: var(--token-spacing-md);
}

.Header__primary {
  align-items: center;
  flex-basis: 100%;
  gap: 0.5rem;

  @media (min-width: 600px) {
    flex-basis: 70%;
    display: flex;
  }
}

.AlignLogo {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.MobileMenu {
  margin-left: auto;
}

.MobileMenu,
.MobileSidebarModal {
  @media (min-width: 600px) {
    display: none;
  }
}

.Header__secondary {
  display: none;
  align-items: center;
  gap: var(--token-spacing-md);

  @media (min-width: 600px) {
    display: flex;
  }
}

// .Header {
//   width: 100%;
//   margin: 12px 0;
//   display: flex;
//   flex-wrap: wrap;
//   color: $white;

//   @media print {
//     display: none;
//   }
// }

// .Header__primary {
//   display: flex;
//   flex-wrap: wrap;
//   align-items: center;
//   gap: 0.5rem;
//   order: 1;
//   flex: 2 0 0;
//   height: 50px;
// }

// @media (min-width: 600px) {
//   .Header__primary {
//     flex-wrap: nowrap;
//     gap: 0rem;
//   }
// }

// .Header__secondary {
//   flex: 1 0 0;
//   order: 2;
//   text-align: right;
// }

// .Header__detail {
//   color: $white;
//   display: block;
//   margin-bottom: 6px;

//   &:visited,
//   &:hover,
//   &:active {
//     color: $white;
//   }
// }
//

.NavLink {
  display: flex;
  padding: var(--token-spacing-inset-xl);
  padding-left: var(--token-spacing-md);
  padding-right: var(--token-spacing-md);
  align-items: center;
  justify-content: space-between;
  gap: var(--token-spacing-xs);
  flex: 1 0 0;
  align-self: stretch;

  @media (min-width: 600px) {
    border-radius: var(--token-border-radius-sm);
    padding: var(--token-spacing-inset-xl);
  }

  border-bottom: 1px solid var(--token-color-border-default);
  background: #fff;

  box-shadow: var(--token-elevation-subtle-shadow);

  text-decoration: none;

  transition: transform 0.2s ease-out, box-shadow 0.2s ease-out;

  &:not(.disabled) {
    &:hover,
    &:focus-visible {
      background: var(--token-color-background-primary-subtle);

      @media (min-width: 600px) {
        background: #fff;
        transform: translateY(-2px);
        box-shadow: var(--token-elevation-subtle-shadow-hovered);
      }
    }
  }
}

.icon {
  display: flex;
}

.chevron {
  flex-shrink: 0;
  flex-grow: 0;
  align-self: center;
}

.stacked {
  display: flex;
  flex-direction: row;
  align-self: start;
  gap: var(--token-spacing-xs);

  @media (min-width: 600px) {
    flex-direction: column;
  }
}

.disabled {
  background: var(--token-color-background-secondary-subdued-default);
  cursor: not-allowed;
  border: none;
  box-shadow: none;

  :global {
    * {
      cursor: not-allowed !important;
    }
  }
}

.card {
  align-items: start;
  border-radius: 0;

  margin-left: -16px;
  margin-right: -16px;

  @media (min-width: 600px) {
    border-radius: var(--token-border-radius-sm);
    margin-left: 0;
    margin-right: 0;
  }
}

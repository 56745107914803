@import 'src/Shared/styles/_variables';

.action {
  background-color: #f8f8f8;
  padding: 3rem 1.5rem;
  border-radius: 1rem;
  text-align: center;

  display: flex;
  flex-direction: column;

  h3 {
    font-size: 1.25rem;
    font-weight: 600;
    margin-left: auto;
    margin-right: auto;
    flex: 1;
  }

  p {
    margin-top: -0.5rem;
  }
}

@import 'src/Shared/styles/variables';

.Consent table {
  border: 1px solid lightgrey;
  border-collapse: collapse;
  text-align: left;
  background: white;
}

.Consent th,
.Consent td {
  border: 1px solid lightgrey;
  padding: 10px;
  vertical-align: top;
  width: 33.33%;
}

.Consent .table-overflow {
  overflow-x: auto;
}

.Consent ul {
  list-style: none;
  padding: 0;
}

.Consent li {
  margin-top: 3rem;
}

.AnaesthetistReview__main {
  padding: 20px;
  background: white;
  box-sizing: border-box;
  min-height: 100vh;
}

.AnaesthetistReview__actions {
  margin: 0 0 20px 0;
  padding: 20px;
  display: flex;
  justify-content: flex-end;
  background: #dcf2f8;
  align-items: flex-start;
}

.AnaesthetistReview__actions-warning p {
  color: #a80000;
  margin: 0;
  padding: 0;
}

.AnaesthetistReview__actions .button {
  margin-left: 20px;
  flex-shrink: 0;
}

.Icon {
  color: var(--token-color-blue-700);
  flex-shrink: 0;
}

.Title {
  width: 100%;
}

.Progress {
  width: 100%;
  @media (min-width: 100px) {
    max-width: 100px;
  }
}

.State {
  white-space: nowrap;
  margin-right: var(--token-spacing-xl);
}

// Ideally we would use Icon's color prop but
// in this scenario we are using a custom colour.
.WarningIcon {
  color: var(--token-color-orange-700);
}

.EpisodeStatusSection {
  margin: 0 auto;
  text-align: center;
  margin-bottom: 1.5rem;
  color: #000;
  max-width: 1000px;
}

.EpisodeStatusSection {
  .EpisodeStatusSection__status {
    display: flex;
    align-items: center;

    label {
      margin-right: 2%;
      font-weight: 600;
    }
  }

  select::-ms-expand {
    display: none;
  }

  select:hover {
    border-color: #888;
  }

  select:focus {
    border-color: #aaa;
    box-shadow: 0 0 1px 3px rgba(59, 153, 252, 0.7);
    box-shadow: 0 0 0 3px -moz-mac-focusring;

    outline: none;
  }

  select option {
    font-weight: normal;
  }
}

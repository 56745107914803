$thumbnail_size: 150px;

.widget_fileUploader {
  .FilePreview {
    list-style: none;
    margin: 0;
    padding: 0;

    display: grid;
    grid-gap: 25px;
    grid-template-columns: repeat(auto-fill, minmax($thumbnail_size, 1fr));
  }

  .FilePreview__fullscreen-trigger,
  .FilePreview__placeholder {
    background: #efefef;
    cursor: pointer;
    padding: 0;
    margin: 0;
    margin-bottom: 5px;
    appearance: none;
    border: none;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
    border-radius: 5px;
    height: $thumbnail_size;
    width: $thumbnail_size;
    outline: none;
    position: relative;
  }

  .FilePreview__placeholder {
    display: inline-flex;
    justify-content: center;
    align-items: center;
  }

  .FilePreview__fullscreen-trigger {
    position: relative;

    &:focus-visible,
    &:hover {
      box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.5), 0 1px 2px 0 rgba(0, 0, 0, 0.2);
    }

    &:focus:not(:focus-visible) {
      box-shadow: inherit;
    }
  }

  .FilePreview__text {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;

    background: #fff;
    padding: 2px;
    font-size: 0.9rem;
    font-weight: 600;
    color: #005671;
  }

  .FilePreview__delete {
    margin: 0 auto;
    display: block;

    color: #555;
    text-decoration: underline;
    background: none;
    border: none;
  }

  .FilePreview video {
    object-fit: fill;
    height: 100%;
    width: 100%;
  }

  .FilePreview__add-file button {
    background: #efefef;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    height: $thumbnail_size;
    width: $thumbnail_size;

    img {
      width: initial;
      height: initial;
    }
  }
}

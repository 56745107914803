@import 'src/Shared/styles/variables';

.AuditTrail-heading {
  border-top: 1px solid $brand-colour-blue;
  padding-top: 12px;
  font-size: 14px;
  margin: 12px 0 6px;
}

.AuditTrail-time {
  min-width: 158px;
  display: inline-block;
}

.AuditTrail-action {
  font-size: 12px;
  vertical-align: middle;
  font-weight: normal;
  padding: 8px 6px 6px;
  background-color: $brand-colour-blue; // Default in case other entries get added without .AuditTrail-action--<name>
  color: $white;
  min-width: 110px;
  text-align: center;
  display: inline-block;
}

.AuditTrail-action-- {
  &created {
    background-color: $audit-trail-created;
  }
  &shared {
    background-color: $audit-trail-shared;
  }
  &unshared {
    background-color: $audit-trail-unshared;
  }
  &description-edited {
    background-color: $audit-trail-description-edited;
  }
  &deleted {
    background-color: $audit-trail-deleted;
  }
  &started {
    background-color: $audit-trail-started;
  }
  &unarchived {
    background-color: $audit-trail-unarchived;
  }
}

.ReviewWorkplan__columns {
  margin-bottom: 2rem;
  margin-top: 1rem;

  @media (min-width: 800px) {
    columns: 2;
    gap: 6rem;
    column-rule: 1px solid #ccc;
  }

  ul {
    margin: 0;
  }
}

.ReviewWorkplan__level-2 > .ReviewWorkplan__level-2--title {
  flex: 1;
}

.ReviewWorkplan__level-2 {
  display: flex;

  break-inside: avoid;
  page-break-inside: avoid;

  margin-bottom: 2rem;
  align-items: center;
}

.ReviewWorkplan__actions {
  flex: 1.5;

  display: flex;
  gap: 1rem;

  @media print {
    display: inline-block; // Flexbox breaks printing

    button {
      display: none;
    }
  }
}

.videoContainer {
  text-align: center;
  max-width: 720px;
  margin: 0 -1rem;
  padding: 0 1rem;

  legend,
  video {
    margin-bottom: 1.5rem;
  }

  h3 {
    font-size: 1.25rem;
    font-weight: 600;
    margin: 0;
  }

  video {
    max-width: 100%;
    max-height: 350px;
  }
}
.error {
  text-align: left;
  max-width: 400px;
  margin-left: auto;
  margin-right: auto;

  margin-bottom: 1rem;
}

.radioItem {
  margin-bottom: 0.75rem;
  text-align: left;
  max-width: 400px;
  margin-left: auto;
  margin-right: auto;

  input {
    margin-right: 0.5rem;
  }
}

@import 'src/Shared/styles/variables';

.TermsAndConditions {
  margin: 0 auto;
  color: $black;

  a {
    color: $black;

    &:visited,
    &:hover,
    &:active {
      color: $black;
    }
  }
}

.TermsAndConditions__subtitle {
  font-size: 1rem;
  font-weight: 600;
}

.TermsAndConditions__list li {
  margin-bottom: 10px;
}

.TermsAndConditions__list-item {
  margin-top: 10px;
}

.TermsAndConditions table {
  border: 1px solid lightgrey;
  border-collapse: collapse;
  text-align: left;
  background: white;
}

.TermsAndConditions th,
.TermsAndConditions td {
  border: 1px solid lightgrey;
  padding: 10px;
  vertical-align: top;
  width: 33.33%;
}

.table-overflow {
  overflow-x: auto;
}

@import 'src/Shared/styles/variables';

.PasswordIndicator {
  margin: 6px 0 12px;
  color: $dark-grey;

  ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  li {
    margin: 0 0 6px;
    position: relative;
    padding-left: 24px;
    line-height: 1.6;
  }
}

.PasswordIndicator-tick-icon {
  position: absolute;
  top: 2px;
  left: 0;

  &::before {
    content: '';
    display: inline-block;
    width: 18px;
    height: 18px;
    background: url('../../images/icons/common/icon-tick.svg') no-repeat;
    margin-right: 6px;
  }
}

.PasswordIndicator-cross-icon {
  position: absolute;
  top: 2px;
  left: 0;

  &::before {
    content: '';
    display: inline-block;
    width: 18px;
    height: 18px;
    background: url('../../images/icons/common/icon-cross.svg') no-repeat;
    margin-right: 6px;
  }
}

@import 'Shared/styles/variables';

.item {
  margin-bottom: 0.4rem;
  border: 1px solid $light-grey;
  border-radius: 0.3em;
  background-color: white;
  font-size: 0.9rem;

  &:hover {
    border: 1px solid $grey;
  }

  &__info {
    display: grid;
    grid-template-columns: 1rem 1fr 0.8fr 0.1fr 0.4fr 1rem;
    grid-template-areas: 'a b c d e f';
    gap: 1rem;
    align-items: center;
  }

  &__title {
    font-size: 0.9rem;
    font-weight: 400;
    grid-area: b;
  }

  &__code {
    grid-area: c;
  }

  &__usage {
    grid-area: d;
    justify-self: end;
    height: 24px;
    width: 49px;
    padding: 5px 8px 5px 8px;
    border-radius: 2px;
    color: #5c5010;
    font-size: 10px;
  }

  &__delete-toggle {
    grid-area: e;
    display: flex;
    justify-content: flex-end;
  }

  &__delete-form {
    margin: 0 1rem 1rem 1rem;
  }
}

.order {
  &--copied {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    height: 31px;

    p {
      margin: 0;
      font-size: 0.9rem;
      font-weight: bold;
      color: green;
    }
  }
}

@import 'src/Shared/styles/variables';

// This has overlap with .ProfileSearch and do both use the same dependency,
// however cannot currently use shared styling due to implementation differences.
.AutoSuggestProcedures {
  &.AutoSuggestProcedures--open {
    z-index: 11;
  }

  .react-autosuggest__suggestions-list {
    list-style: none;
    padding: 0;
  }

  &.AutoSuggestProcedures--open {
    .react-autosuggest__suggestions-container--open {
      position: absolute;
      left: 2rem;
      right: 2rem;
      margin-top: 0;
      max-height: 50vh;
      overflow-y: auto;
      z-index: 10;

      background-color: #fff;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      border-bottom-left-radius: 0.5rem;
      border-bottom-right-radius: 0.5rem;
      border: 1px solid #aaa;
      border-top: 0;
      box-shadow:
        0 1px 3px 0 rgb(0 0 0 / 10%),
        0 1px 2px 0 rgb(0 0 0 / 6%);
    }
  }

  @media (min-width: 950px) {
    position: relative;

    &.AutoSuggestProcedures--open {
      .react-autosuggest__suggestions-container--open {
        width: calc(100% + 2px);
        left: -1px;
        right: -2px;
        top: 1rem;
      }

      .react-autosuggest__container {
        position: relative;
      }

      &:not(.AutoSuggestProceduresBeta) .AutoSuggestProcedures__input {
        &:focus:not([value='']) {
          padding: 1rem;
          position: absolute;
          top: -2rem;
          padding-right: 3rem;
        }
      }
    }
  }

  .AutoSuggestProcedures__suggestion-code {
    // color: $black;
    font-weight: 600;
    margin-right: 2rem;

    line-height: 1.2em;
  }

  .AutoSuggestProcedures__suggestion-desc {
    font-size: 1rem;
  }

  .react-autosuggest__suggestion {
    cursor: pointer;
    padding: 1rem;
    border-bottom: 1px solid #eee;

    &:hover,
    &:focus,
    &.react-autosuggest__suggestion--highlighted {
      background-color: var(--token-color-row-highlight);
    }

    > div {
      display: flex;
      align-items: center;

      :first-child {
        flex-basis: 50px;
      }
    }
  }

  .AutoSuggestProcedures__loader {
    position: absolute;
    top: -1rem;
    right: 0.9rem;
    z-index: 12;
  }
}

.input--error .AutoSuggestProcedures,
.input--error .AutoSuggestProcedures input {
  color: #00556e;
}

.AutoSuggestProcedures:not(.AutoSuggestProceduresBeta)
  .AutoSuggestProcedures__input {
  border: none;
  width: 100%;
  outline: none;
  padding: 0;
}

.AutoSuggestProceduresBeta input {
  width: 100%;
  line-height: 1.8;
  border: 1px solid #405159;
  border: 1px solid var(--token-color-border-secondary-default);
  background-color: #fff;
  box-sizing: border-box;
  border-radius: 4px;
  border-radius: var(--token-border-radius-xs);
  padding: 2px 6px;
  font-size: 16px;
  font-size: var(--token-font-size-md);
  color: #000;
  -webkit-appearance: none;
}

.AutoSuggestProcedures.AutoSuggestProceduresBeta {
  @supports (grid-template-rows: subgrid) {
    .react-autosuggest__suggestions-list {
      display: grid;
      grid-template-columns: max-content 1fr;
      gap: 0 var(--token-spacing-md);
    }

    .react-autosuggest__suggestion {
      display: grid;
      grid-template-columns: subgrid;
      grid-column: span 2;
    }

    .AutoSuggestProcedures__suggestion-code {
      margin-right: 0;
    }
  }

  .react-autosuggest__suggestions-container--open {
    top: auto;

    @media (max-width: 599px) {
      left: 1rem;
      right: 1rem;
    }
  }

  .AutoSuggestProcedures__loader {
    top: 0.3rem;
  }
}

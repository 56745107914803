@import 'src/Shared/styles/variables';

.AnswerCategory {
  display: inline-block;
  border-radius: 12px;
  height: 18px;
  width: 25px;
}

.AnswerCategory[data-category='red'] {
  background-color: var(--token-color-red-700);
}

.AnswerCategory[data-category='amber'] {
  background-color: #ff8a00;
}

.AnswerCategory[data-category='green'] {
  background-color: #a4c83c;
}

.AnswerCategory[data-category='na'] {
  background-color: #737373;
}

@import 'src/Shared/styles/variables';

.expansion-panel {
  position: relative;
  &__header {
    border: 1px solid rgba(255, 255, 255, 0);
    border-radius: 0.5rem;
    background-color: $white;
    box-shadow:
      0px 1px 3px rgba(0, 0, 0, 0.1),
      0px 1px 2px rgba(0, 0, 0, 0.06);
    margin-bottom: 0.5rem;
    font-size: 0.9rem;
    line-height: 2;

    &:hover {
      border: 1px solid $light-grey;
    }
  }
  &__title-view {
    display: grid;
    grid-template-columns: 2fr 1fr;
    align-items: last baseline;
    height: 4.5rem;
    padding: 0 1rem 1.3rem 1rem;
  }

  &__rename-form {
    padding-top: 0.5rem;
    display: flex;
    gap: 0.6rem;
    align-items: last baseline;

    button:first-of-type {
      align-self: last baseline;
      justify-self: center;
    }

    .InlineValidationError {
      font-size: 0.8rem !important;
      margin-top: 0.5rem;
    }
  }

  .Form-control {
    margin-bottom: 0;

    .TextInput {
      border: 2px solid $light-grey;
      border-radius: 4px;
      padding-left: 0.4rem;
      width: 11.3rem;
      height: 2rem;
      outline: none;
      font-size: 1rem;
    }
  }
  &__delete-form {
    margin: 1.5rem;
  }

  &__cancel-button {
    height: 1.6rem;
    color: $font-colour;
    background-color: $white;
    border: none;
    padding: 0;
    text-decoration-line: underline;

    &:hover,
    &:focus {
      color: $black;
      cursor: pointer;
    }
  }

  .expansion-panel__title {
    margin: 0;
    font-size: 1rem;
    padding-left: 0.4rem;
    color: $black;
    font-weight: 600;
  }

  &__button-container {
    display: flex;
    justify-self: flex-end;
  }

  &__toggle-button {
    height: 1.6rem;
    display: flex;
    align-items: center;
    gap: 0.03rem;
    font-size: 0.88rem;
    color: $font-colour;
    font-weight: 400;
    border: none;
    background-color: $white;

    &:hover,
    &:focus {
      cursor: pointer;
      color: black;
      text-decoration-line: underline;
    }

    &:hover path,
    &:focus path {
      stroke: black;
    }
  }

  &__options-button {
    width: 1.6rem;
    height: 1.6rem;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    border: 1px solid rgba(255, 255, 255, 0);

    &:hover,
    &:focus {
      cursor: pointer;
      border: 1px solid var(--token-color-text-heading-default);
      border-radius: var(--token-border-radius-xs);
      outline: none;
    }

    &:active {
      cursor: pointer;
      background-color: var(--token-color-text-heading-default);

      path {
        stroke: white;
      }
    }
  }

  &__options-menu {
    display: flex;
    flex-direction: column;
    gap: 0.8rem;
    position: absolute;
    top: 3.5rem;
    right: 0rem;
    z-index: 100;
    margin: 0;
    background: white;
    box-shadow:
      0px 1px 3px rgba(0, 0, 0, 0.1),
      0px 1px 2px rgba(0, 0, 0, 0.06);
    border-radius: 0.2rem;
    padding: 1.5rem 1.5rem 1.5rem 0.8rem;
    list-style-type: none;
  }
}

.expansion-panel[aria-expanded='true'] {
  border-radius: 0.5rem;
  background-color: $white;
  box-shadow:
    0px 1px 3px rgba(0, 0, 0, 0.1),
    0px 1px 2px rgba(0, 0, 0, 0.06);
  margin-bottom: 0.5rem;

  .expansion-panel__header {
    box-shadow: none;
    margin-bottom: 0;

    &:hover {
      border: 1px solid rgba(255, 255, 255, 0);
    }
  }

  .expansion-panel__content {
    padding-bottom: 1.5rem;
  }
}

@import '../../../../Shared/styles/variables';

.update-workplan {
  &__add-btn-container {
    margin-bottom: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;

    h3 {
      margin-bottom: 0;
    }

    &--empty {
      background-color: rgb(239, 238, 238);
      padding: 0.5rem 1rem;
      border-radius: 0.3em;
    }
  }

  &__add-category {
    border: 1px solid $light-grey;
    border-radius: 0.5rem;
    background-color: $white;
    padding: 1rem 1rem 0 1rem;
    margin-bottom: 0.5rem;
    display: flex;
    align-items: last baseline;
    gap: 0.6rem;
    font-size: 0.9rem;

    label {
      margin-bottom: 0.5rem;
      font-size: 0.9rem;
    }

    #text,
    #value,
    #category {
      width: 100%;
      font-size: 1rem;
    }

    button {
      align-self: last baseline;
      justify-self: center;
    }

    .InlineValidationError {
      font-size: 0.8rem !important;
    }

    &__cancel-button {
      background-color: $white;
      border: none;
      padding: 0;
      text-decoration-line: underline;

      &:hover,
      &:focus {
        color: $font-colour;
        cursor: pointer;
      }
    }
  }
}

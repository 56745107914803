@import 'src/Shared/styles/variables';

.SurveyLayout {
  color: var(--token-color-text-default);

  margin: 0 auto;
  margin-top: 50px;
  max-width: 960px;

  box-sizing: border-box;

  *,
  *:before,
  *:after {
    box-sizing: inherit;
  }

  .sv_main {
    margin-top: var(--token-spacing-default);
  }

  .sv_progress {
    text-align: right;
    font: var(--token-typography-body-md-regular);
    color: var(--token-color-text-secondary);
    margin-bottom: var(--token-spacing-xs);
  }

  .sv_page_title {
    font: var(--token-typography-heading-xl-bold);
    color: var(--token-color-text-heading-default);
    margin-top: 0;
    margin-bottom: var(--token-spacing-2xl);
  }

  .sv_q_text_root {
    width: 35ch;
    max-width: 100%;
    padding: var(--token-spacing-inset-squish-sm);
    border-radius: var(--token-border-radius-xs);
    border: 1px solid var(--token-color-border-secondary-default);
    line-height: 1;
  }

  .sv_container {
    box-shadow:
      0 1px 3px 0 rgba(0, 0, 0, 0.1),
      0 1px 2px 0 rgba(0, 0, 0, 0.06);
    background-color: #fff;
    border-radius: 0.5rem;
    padding: var(--token-spacing-md);
    position: relative;
    margin-bottom: var(--token-spacing-4xl);

    @media (min-width: 600px) {
      padding: var(--token-spacing-4xl);
    }
  }

  .patient-name {
    color: #ffffff;
  }

  .sv_container .sv_row:before {
    display: none;
  }

  // SurveyJS added an update that caused two progress bars to appear.
  // For now we are just hiding one of them.
  .sv_progress_bar > span {
    display: none;
  }

  .sv_header {
    display: none;
  }

  .sv_q_required_text {
    display: none;
  }

  .sv_nav {
    margin-top: 50px;
  }

  .sv_prev_btn {
    display: none;
  }

  .sv_next_btn,
  .sv_complete_btn {
    position: initial;
    width: 100%;

    @media (min-width: 625px) {
      width: initial;
    }
  }

  .sv_q_description {
    color: var(--token-color-text-secondary);
    margin: var(--token-spacing-stack-default);
    line-height: 1.5;
    font: var(--token-typography-body-md-regular);
  }

  .sv_qstn {
    overflow: initial;
  }

  .sv_container .sv_qstn {
    padding: 0;
  }

  .sv_row:first-child .sv_q_matrix {
    margin: 0 auto;
    text-align: left;
  }

  .RecoveryEntry & .sv_qstn {
    text-align: left;
    margin-bottom: 2rem;

    .sv_q_matrix_dynamic {
      text-align: left;
      max-width: fit-content;

      tr {
        padding: 1rem;
      }
    }
  }

  .sv_q_title {
    color: var(--token-color-text-heading-default);
    font: var(--token-typography-body-lg-semibold);
    margin-bottom: var(--token-spacing-md);
    margin-top: var(--token-spacing-3xl);
    text-align: left;
  }

  .sv_row:first-child .sv_q_title {
    font: var(--token-typography-heading-xl-bold);
    margin-bottom: var(--token-font-size-2xl);
    margin-top: 0;
  }

  .sv_qstn_error_top {
    color: var(--token-color-danger-default);
    margin-bottom: 10px;
  }

  .sv_qstn textarea {
    padding: 1rem;
    resize: vertical;
    max-width: 100%;
    width: 100%;
  }

  .sv_row:first-child fieldset[role='radiogroup'] {
    margin-bottom: -2rem;
    text-align: center;
  }

  .sv_qstn .sv_q_radiogroup {
    width: initial;
    text-align: center;
    padding-right: 1.2rem;

    @media (min-width: 600px) {
      padding-right: 2.1rem;
    }
  }

  .sv_qstn .sv_q_radiogroup:last-child {
    padding-right: 0;
  }

  input.Survey__next {
    margin: 0 auto !important;
    padding: 12px var(--token-spacing-2xl) !important;
    font: var(--token-typography-body-lg-regular) !important;
  }

  [type='checkbox'] {
    accent-color: var(--token-color-background-primary-bold-default);
    transform: scale(1.5);
    margin-left: 2px;
  }
}

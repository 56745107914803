.Icon {
  color: var(--token-color-blue-700);
  flex-shrink: 0;
}

.State {
  white-space: nowrap;
  margin-right: var(--token-spacing-xl);
}

.Title {
  width: 100%;
}

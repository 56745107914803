@import 'Shared/styles/variables';

.alert {
  margin: 0 auto;
  padding: 20px;
  box-sizing: border-box;
  border-radius: 5px;

  &__full-width {
    width: 100%;
    max-width: 100%;
  }

  &__danger {
    background: #f8d7da;

    a:not(.Button) {
      color: #721c24;
    }
  }

  &__warning {
    background: #fff3cd;

    a:not(.Button) {
      color: #856404;
    }
  }

  &__success {
    background: #ecf7d2;

    a:not(.Button) {
      color: #0d1103;
    }
  }

  &__info {
    background: #dcf2f8;

    a:not(.Button) {
      color: #00556e;
    }

    p {
      max-width: 100%;
    }
  }

  &--content {
    margin: 0 auto;
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;

    &__full-width {
      max-width: 100%;
    }

    p {
      margin: 0 !important;
      padding: 0;
    }

    p + p {
      padding: 0;
      margin: 10px 0 0;
    }

    a:not(.Button) {
      text-decoration: underline;

      &:hover {
        color: black;
      }
    }

    .Button {
      align-self: center;
      margin-top: 20px;
    }
  }
}

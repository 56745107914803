@import 'src/Shared/styles/_variables';

.notification.hide {
  display: none;
}

.notification {
  background-color: #fff;
  margin-left: -1rem;
  margin-right: -1rem;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  padding: 0.7rem 2rem;
  margin-bottom: 0;
  display: flex;
  gap: 0.25rem;

  @media print {
    display: none;
  }

  .alignIcon {
    display: flex;
    align-items: center;
    flex-grow: 1;
  }

  img {
    max-width: 25px;
    margin-right: 1rem;
  }

  button {
    color: var(--token-color-text-heading-default);
    flex-shrink: 0;
  }
}

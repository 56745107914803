.TopNavigation {
  background: #fff;
  min-height: 60px;

  margin-left: -1rem;
  margin-right: -1rem;

  display: flex;
  align-items: center;
  padding: 0;

  box-shadow: var(--token-elevation-subtle-shadow);
  z-index: 2;
  &:empty {
    display: none;
  }
}

.actions {
  margin-left: auto;
  padding-right: 1rem;
  display: none;

  :global {
    /* REMOVE */
    .Button {
      margin: 0;
    }
  }

  @media (min-width: 600px) {
    display: block;
  }
}

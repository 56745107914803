@media print {
  .Header {
    display: none;
  }

  .hidden-on-print {
    display: none;
  }

  .PatientProfileSummary {
    display: none;
  }

  .Breadcrumb {
    display: none;
  }

  .PageHeader {
    display: none;
  }

  footer {
    display: none;
  }

  .AnaesthetistReview__main {
    padding: 0;
    border: none;
  }

  .AnaesthetistReview {
    padding: 0;
    margin: 0;

    /* Force sections with background colours to print background 
       Potentially not great as overrides user choice.
       */
    -webkit-print-color-adjust: exact;
    print-color-adjust: exact;

    textarea,
    input {
      display: none !important;
    }
  }
}

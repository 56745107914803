.FilterWorkplan {
  // Prevent the free-text Other section from showing when filtering.
  .sv_qstn[data-name*='other_'] {
    display: none;
  }

  .sv_row,
  .sv_row div {
    min-width: 0 !important;
  }

  .sv_p_title_expandable {
    padding: 0;
    margin-bottom: 0;
    margin-top: 1.5rem;
    outline: none;
    user-select: none;
  }

  .sv_p_title_expandable:focus-visible {
    outline-color: #fd0;
    outline-style: solid;
  }

  .sv_qstn .sv_q_title {
    display: none;
  }

  .sv_p_title_expandable::after,
  .sv_q_title_expandable::after {
    outline: none;
  }
}

.CreateClinicalNote {
  margin: 0 auto;
  max-width: 80ch;
}

.CreateClinicalNote__actions {
  display: flex;
  flex-wrap: wrap;
  align-items: baseline;
  margin-top: 0.5rem;
}

.CreateClinicalNote__actions input[type='checkbox'] {
  margin-right: 0.25rem;
}

.CreateClinicalNote__submit {
  margin-left: 1rem;
}

.CreateClinicalNote__cancel {
  margin-right: auto;
}

.CreateClinicalNote__error {
  color: red;
  font-weight: 600;
  margin-bottom: 0.6rem;
}

@import 'src/Shared/styles/variables';

.dashboard {
  display: grid;
  grid-template-columns: 1fr;
  gap: 2rem;

  @media (min-width: 1000px) {
    grid-template-columns: 1fr 1fr;
  }
}

.separator {
  border: 1px solid #dadada;
  margin: 1rem 0 1.5rem 0;
}

.recoveryContent {
  margin-left: 1.4rem;
  padding-right: 3rem;
  margin-bottom: 2rem;
  max-width: 500px;
}

.buttonRight {
  margin-top: 0.5rem;
  text-align: right;
}

ul.procedures {
  padding-left: 0;

  > li {
    padding-left: 0;
    list-style: none;
  }
}

.Tabs {
  overflow-x: auto;
}

.Tabs--border {
  border-bottom: 3px solid #2793d1;
  margin-bottom: 1rem;

  .Tabs__button {
    border-bottom: 0;
  }
}

.Tabs__button {
  cursor: pointer;
  background-color: #f7f7f7;
  appearance: none;
  border: none;
  padding: 1rem;
  margin: 0;

  border: 1px solid #e6e6e6;
  border-right: 0;
  outline: none;

  &:hover,
  &:focus {
    background-color: #e6e6e6;
  }

  &:focus-visible {
    outline: 3px solid #fd0;
    outline-offset: -2px;
  }

  &:last-child {
    border-right: 1px solid #e6e6e6;
  }
}

.Tabs__button--selected {
  cursor: initial;
  background-color: #dcf2f8;
  color: #00556e;
  border: 1px solid #c2d9e7;

  &:hover,
  &:focus {
    background-color: #dcf2f8;
  }

  &:last-child {
    border-right: 1px solid #c2d9e7;
  }
}

.Tabs__button--selected + .Tabs__button {
  border-left: 0;
}

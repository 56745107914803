.PoaSummary__main {
  box-sizing: border-box;
  color: #00556e;
  min-height: 500px;

  position: relative; // To center <Loader />
}

.PoaSummary__tabs {
  max-width: 1000px;
  margin: 0 auto 20px;
  padding: 0;
  list-style: none;
  text-align: center;
}

.PoaSummary__tabs li {
  margin: 0;
  padding: 0;
  display: inline-block;
}

.PoaSummary__tabs li+li {
  margin-left: 10px;
}

.PoaSummary__print {
  width: 90vw;
  height: 75vh;
}

.PoaSummary__ASA-grade-entry {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem 3rem;
}

.PoaSummary__questionset--page:not(:last-child) {
  padding-bottom: var(--token-spacing-lg);
  margin-bottom: var(--token-spacing-xs);
  border-bottom: 1px solid var(--token-color-blue-700);
}

.poa-summary__questionset--question :last-child {
  margin-bottom: 0;
}

.poa-summary__questionset--comments {
  border-bottom: 1px solid var(--token-color-blue-700);
  margin-bottom: var(--token-spacing-xs);
}

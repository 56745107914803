.DetailsForm {
  --tile-padding: 2rem;
  --form-margin: 0;

  min-height: 300px;
  position: relative;

  .input,
  .select {
    border-bottom: 1px solid #0091da;
    color: #00556e;
    line-height: 1;
    padding: 0.6rem 0;
    margin: 0;
    align-items: center;
  }

  .input--error,
  .select--error {
    color: #e30303;

    input,
    select,
    legend,
    .required {
      color: #e30303;
    }
  }

  .input__label,
  .select__label {
    width: 40% !important;
    text-align: right;
  }

  label,
  legend {
    user-select: none;
  }

  legend {
    text-align: right;
    width: 100%;
  }

  // .input > :nth-child(2):hover:not(:disabled):not(:focus) {
  //   border-bottom: 1px solid #ccc;
  // }

  .input > :nth-child(2),
  .select__select {
    margin-left: 2rem;
    border: 1px solid transparent;
    flex: 1;

    &[type='checkbox'] {
      flex: none;
    }
  }

  .input--center,
  .select {
    display: flex;
    flex-wrap: wrap;
  }

  .select__select {
    width: auto !important;
  }

  .required {
    margin-left: 5px;
    color: #55b9e2;
  }

  .InlineValidationError {
    font-size: 16px;
    margin-top: 7px;
  }
}

.input .PhoneInputInput {
  background: none;
  border: none;
  width: 100%;

  outline: none;
}

.input .PhoneInputInput:-webkit-autofill {
  box-shadow: inset 0 0 0 1000px #fff;
}

.videoPlayIcon {
  position: absolute;
  width: 75px;
  height: 75px;
  display: block;
  shape-rendering: geometricprecision;
  opacity: 0.9;

  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.videoPlaceholder {
  appearance: none;
  border: 0;
  background: transparent;
  display: block;
  width: 100%;
  margin: 0;
  line-height: 0;
  padding: 0;
}

.videoPlaceholder:hover {
  cursor: pointer;

  & .videoPlayIcon {
    opacity: 0.7;
  }
}

.videoPlaceholderImage {
  border-radius: 0.5rem;
  width: 100%;
}

.video {
  border-radius: 0.5rem;
  max-height: 85vh;
}

.video::-webkit-media-text-track-container {
  display: var(--webkit-text-track-display);
}

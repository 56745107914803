@import 'src/Shared/styles/variables';

// This has overlap with .ProfileSearch and do both use the same dependency,
// however cannot currently use shared styling due to implementation differences.
.AutoSuggestSpecialties {
  position: relative;

  &.AutoSuggestSpecialties--open {
    z-index: 11;

    .AutoSuggestSpecialties__input {
      padding: 0.5em 0 0.5em 0.5em;
    }
  }

  .react-autosuggest__suggestions-list {
    list-style: none;
    padding: 0;
  }

  &.AutoSuggestSpecialties--open {
    .react-autosuggest__suggestions-container--open {
      position: absolute;
      left: -0.05rem;
      right: -0.05rem;
      margin-top: 0;
      max-height: 50vh;
      overflow-y: auto;
      z-index: 10;

      background-color: #fff;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      border-bottom-left-radius: 0.5rem;
      border-bottom-right-radius: 0.5rem;
      border: 1px solid #aaa;
      border-top: 0;
      box-shadow: 0 1px 3px 0 rgb(0 0 0 / 10%), 0 1px 2px 0 rgb(0 0 0 / 6%);
    }
  }

  @media (min-width: 950px) {
    position: relative;

    &.AutoSuggestSpecialties--open {
      .react-autosuggest__suggestions-container--open {
        width: calc(100% + 2px);
        left: -1px;
        right: -2px;
        top: 1rem;
      }

      .react-autosuggest__container {
        position: relative;
      }

      .AutoSuggestSpecialties__input {
        &:focus:not([value='']) {
          padding: 1rem;
          position: absolute;
          top: -2rem;
          padding-right: 3rem;
        }
      }
    }
  }

  .AutoSuggestSpecialties__input {
    border: none;
    width: 100%;
    outline: none;
    padding: 0;
  }

  .react-autosuggest__container--open {
    .AutoSuggestSpecialties__input {
      padding-left: 1rem;
    }
  }

  .AutoSuggestSpecialties__suggestion-code {
    // color: $black;
    font-weight: 600;
    margin-right: 2rem;
    line-height: 1.2em;
  }

  .AutoSuggestProcedures__suggestion-disabled {
    color: #76927c;
    display: flex;
    align-items: center;
  }

  .AutoSuggestProcedures__max-suggestions {
    color: rgb(163, 161, 161);
  }

  .react-autosuggest__suggestion {
    cursor: pointer;
    padding: 1rem;
    border-bottom: 1px solid #eee;

    &:hover,
    &:focus,
    &.react-autosuggest__suggestion--highlighted {
      background-color: var(--token-color-row-highlight);
    }

    > div {
      display: flex;
      align-items: center;
    }
  }
}

.input--error .AutoSuggestSpecialties,
.input--error .AutoSuggestSpecialties input {
  color: #00556e;
}

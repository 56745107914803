.HospitalShortHq {
  --survey-padding-left: 1rem;
  margin: 0 auto;
  max-width: 1300px;

  .sv_qstn {
    overflow: initial;
  }

  .Survey__complete {
    margin: 0 auto;
  }

  #episiode-assist-toggle {
    display: block;
    margin: 0 auto;
  }

  .UpdateWorkplan {
    display: none;
  }
}

.sv_q_checkbox {
  margin-bottom: var(--token-spacing-default);
}

span.sv_q_checkbox_control_label {
  padding-left: 1rem;
}

.HospitalShortHq--workplan {
  --survey-padding-right: 5px;
  max-width: 1800px;

  .HospitalShortHq__workplan-columns {
    display: grid;
    grid-template-columns: 3.5fr minmax(300px, 1fr);
    position: relative;
    padding-left: 1rem;

    .UpdateWorkplan {
      display: initial;
    }
  }
}

.delegate-shorthq-modal,
.edit-shorthq-modal {
  max-width: 500px;
}

.ClinicalNote__badge {
  display: inline-block;
  padding: 5px 8px;
  margin-left: auto;

  background: #005671;
  border: none;
  border-radius: 3px;
  color: white;

  p {
    font-size: 10px;
    line-height: 1;
    font-weight: 600;
    text-transform: uppercase;
    margin: 0;
  }
}

.ClinicalNote__badge--warning {
  background: #ff8a00;
}

.react-autosuggest__link {
  display: block;
  padding: 1.25rem;
  cursor: pointer;
  border-bottom: 1px solid #ccc;
}

.react-autosuggest__suggestion--highlighted .react-autosuggest__link,
.react-autosuggest__link:hover {
  background-color: var(--token-color-row-highlight);
}

.react-autosuggest__link {
  color: #000;
  text-decoration: none;
}

.ProfileSearchRow__name {
  margin-bottom: 1rem;
  font-weight: 700;
  font-size: 17px;
}

.ProfileSearchRow__hospital {
  margin-left: auto;
  font-weight: 600;
  font-size: 14px;
  color: #777;
}

.container {
  margin-left: auto;
  margin-right: auto;
  font-size: 18px;
  line-height: 1.4;
  font-family: 'Open Sans', sans-serif;
  font-weight: normal;
  min-height: 70vh;
  max-width: 1340px;
  margin: 0 auto;

  h2 {
    font-size: 1.5rem;
    color: #202020;
    font-weight: 400;
    margin-bottom: 1rem;
  }
}

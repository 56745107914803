.RecoveryDiaryOverview__day {
  background: #f3f4f6;
  color: #555;
  text-align: center;

  border-top-left-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;

  padding: 0.5rem;
  margin-right: 0.5rem;
}

.RecoveryDiaryOverview__day-label {
  font-size: 0.75rem;
  line-height: 1rem;
  display: block;
}

@import 'src/Shared/styles/_variables';

.alert {
  display: none;
}

.details {
  display: block;
}

.summary {
  cursor: pointer;
}

.content {
  margin-top: var(--token-spacing-default);
}

@media (min-width: 910px) {
  .alert {
    display: block;
  }

  .details {
    display: none;
  }

  .content {
    margin-top: 0;
    display: flex;
    gap: var(--token-spacing-default);
  }
}

.select {
  margin-bottom: 10px;
}

.select label.select__label {
  width: 50%;
  text-align: right;
  box-sizing: border-box;
  padding-right: 5px;
  margin-right: 0;
}

.select select.select__select {
  background: transparent;
  width: 50%;
  border: none;
  outline: none;
}

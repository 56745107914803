.UpdateWorkplan {
  background-color: #f4f4f4;
  position: sticky;
  align-self: start;
  top: 0;
  max-height: 90vh;
  overflow-y: auto;
  overflow-x: hidden;

  padding-left: 1rem;
  padding-right: 1rem;

  .sv_p_container {
    color: #005671;
  }

  .sv_matrix_cell_header {
    display: none;
  }

  .sv_q_footer .sv_matrix_dynamic_button {
    margin-top: 5px;
  }
}

.UpdateWorkplan__container {
  padding-bottom: 2rem;
}

.PoaSummary__main .UpdateWorkplan {
  position: initial;
}

.PoaSummary__main .UpdateWorkplan .sv_p_root {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}

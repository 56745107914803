.RadioBlockWidget {
  max-width: 330px;
}

[data-name='rockWood'] .RadioBlockWidget {
  max-width: 100%;
}

[data-name='lungs_covid_ethnicity'] .RadioBlockWidget {
  max-width: 600px;
}

.TileView__header {
  margin-bottom: 1.5rem;
}

.TileView__header h1 {
  margin: 0;
}

.TileView__header p {
  color: rgb(75, 85, 99);
  display: inline-block;
  margin: 0;
  margin-right: 15px;
}

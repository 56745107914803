@import 'src/Shared/styles/variables';

.Navigation {
  width: 100%;

  @media (min-width: 600px) {
    width: auto;
  }
}

.NavigationList {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
}

.NavigationItem {
  display: inline-block;
  height: 100%;

  flex: 1;
  text-align: center;
  white-space: nowrap;

  a {
    display: block;
    padding: 1rem 2rem;
    border-bottom: 6px solid transparent;
    // transition: background, border-color 0.2s ease-out;
  }

  :global {
    a.selected {
      border-bottom: 6px solid $brand-colour-blue;
      background-color: var(--token-color-background-primary-subtle);
    }
  }
}

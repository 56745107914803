.backgroundOverlay {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  transition: background-color 1s;
  box-sizing: border-box;
  z-index: 10;
}

.SurveyContent__title {
  color: #005671;
  font-size: 1.5rem;
  text-align: center;
  margin: 0;
  margin-bottom: 50px;
  margin-bottom: 1.5rem !important;
  margin-top: 50px;
  width: 100%;
}

.SurveyContent {
  min-height: 300px;
  max-width: 960px;
  margin: 0 auto;
  margin-top: 50px;

  background-color: #fff;
  padding: 20px;
  position: relative;
}

.SurveyContent *,
.SurveyContent ::before,
.SurveyContent ::after {
  box-sizing: border-box;
}

.SurveyContent__inner {
  max-width: 60ch;
  margin: 0 auto;
  color: #000;
  text-align: left;
  line-height: 1.4;
}

.SurveyContent__inner > .Button {
  display: block;
  margin: 2.5rem auto 2rem auto;
}

.SurveyContent__actions {
  padding: 2rem 0;
}

.SurveyContent__actions :first-child {
  margin: 0 1.5rem 0 auto;
}

.SurveyContent__actions :last-child {
  margin-right: auto;
  align-self: center;
}

.SurveyContent__actions .Button {
  margin-bottom: 2rem;
  width: 100%;
}

.SurveyContent__emergency {
  font-style: italic;
  font-weight: 600;
}

@media (min-width: 500px) {
  .SurveyContent__actions {
    display: flex;
    align-items: baseline;
  }

  .SurveyContent__actions .Button {
    margin-bottom: 0;
    width: auto;
  }
}

@import 'src/Shared/styles/variables';

.widget_numberScale {
  display: flex;
}

.widget_numberScale input[type='radio'] {
  cursor: pointer;
  opacity: 0;
  position: absolute;
  z-index: -1;

  & + label {
    user-select: none;
    border: 1px solid #ccc;
    background-color: #f7f7f8;
    color: #005671;
    width: 20px;
    text-align: center;
    padding: 6px 18px;
    line-height: 1;
    cursor: pointer;
    border-radius: 3px;
    transition: all 0.3s;
    box-shadow: 1px 1px 0 rgba(180, 180, 180, 0.1);
    width: 100%;

    &:hover {
      background-color: #bfe3ff;
    }
  }

  &:focus + label {
    border: 1px solid $font-colour;
  }

  &:checked + label {
    background-color: #0091ff;
    color: #ffffff;
  }

  &:checked:disabled + label {
    background-color: #777;
  }
}

.widget_numberScale .numberScale-container {
  margin: 0 auto;

  ul {
    padding: 0;
    display: flex;
    list-style: none;
  }

  li {
    margin: 0 3px;
  }
}

.widget_numberScale .numberScale--labels {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  justify-content: space-between;
}

.ContentPageSection {
  margin-top: 0;
}

.ContentPageSection + .ContentPageSection {
  margin-top: 2rem;
}

.ContentPageSection > p {
  margin-top: 0;
  margin-bottom: 1rem;
}

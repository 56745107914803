@import 'src/Shared/styles/variables';

.ContentPage {
  background-color: var(--token-color-background-secondary-subtle);
  color: $black;
  line-height: 1.5;

  a {
    color: $black;

    &:visited,
    &:hover,
    &:active {
      color: $black;
    }
  }

  .ContentPage__wrapper {
    max-width: 900px;
    margin: 0 auto;
    padding: 2rem 1rem 4rem 1rem;
  }

  summary {
    cursor: pointer;
    color: #005671;
    font-weight: 600;
    user-select: none;
    font-size: 1.1rem;
  }

  details .Callout {
    margin-top: 1rem;
    margin-left: 2px;
    padding-top: 1rem;
    padding-bottom: 1rem;
    padding-left: 1rem;
  }

  .ToggleButton {
    margin-left: 1rem;
  }

  .ToggleButton-label {
    font-weight: 600;
    font-size: 14px;
  }

  h2 {
    vertical-align: middle;
  }

  h2,
  .ToggleButton {
    display: inline-block;
  }

  table {
    border: 1px solid lightgrey;
    border-collapse: collapse;
    text-align: left;
    background: white;

    th,
    td {
      border: 1px solid lightgrey;
      padding: 10px;
      vertical-align: top;
      width: 33.33%;
    }

    ul {
      padding-left: 0.75rem;
    }
  }
}

@import 'src/Shared/styles/variables';

:root {
  --lifeboxBlue: #005774;
}

.logo {
  display: flex;
  text-decoration: none;

  .glyph {
    display: inline-block;
    margin-right: 12px;

    &--lifebox {
      width: 35px;
      height: 45px;
    }

    &--think {
      width: 40px;
    }
  }

  .wordmark {
    display: inline-block;
    width: 113px;
  }

  &.logoTraining {
    align-items: start;

    .glyph {
      width: 24px;
      height: initial;
    }

    .wordmark {
      width: 108px;
      height: 40px;
    }
  }
}

.header {
  background-color: #0d1c30;

  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 1.5rem;
}

.fixed {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 99;
  margin-left: -16px;
  margin-right: -16px;
}

.profile {
  color: #fff;
  font-size: 1rem;
  align-items: center;
  position: relative;
  text-align: right;

  a {
    color: #fff;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
}

.signOut {
  font-size: 1rem;
  margin-top: 0.25rem;

  button {
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
}

.logo {
  font-size: 1.1rem;
  color: #fff;
  align-items: center;
}

.logo:hover,
.logo:focus {
  color: #e3e3e3;
}

.MenuButton {
  button {
    margin: 0;
  }
}

.DesktopMenu {
  @media (max-width: 600px) {
    display: none;
  }
}

.hidden {
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

:global {
  // TODO: Once image background has been completely removed this can go
  .patientBody {
    background: #f8f8f8;
    padding-top: 80px;

    &.trainingEnv {
      padding-top: 110px;
    }

    * {
      font-family: 'Open Sans', sans-serif !important;
    }

    &:after {
      display: none;
    }

    strong {
      font-weight: 600;
    }

    .Footer,
    .Footer a,
    .Backlink_Link,
    .PageTitle {
      color: #0d1c30;
    }
  }
}

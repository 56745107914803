@media not print {
  .AnaestheticChart__main {
    color: #00556e;
  }
}

.AnaestheticChart__actions {
  width: 100%;
  margin: 0 auto 20px;

  display: flex;

  .Button {
    margin-left: auto;
  }
}

.AnaestheticChart__grid {
  border: 1px solid black;
  width: 100%;
  margin: 0 auto;
  color: black;
  font-size: 14px;
}

.AnaestheticChart__row {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: stretch;
  border-top: 1px solid black;
  break-inside: avoid;
}

.AnaestheticChart__row:first-child,
.AnaestheticChart__row .AnaestheticChart__row {
  border-top: none;
}

.AnaestheticChart__columns {
  gap: 0 1.5rem;
  columns: var(--column-count, 2);
}

.AnaestheticChart__col {
  flex: 1 0 50%;
  border-right: 1px solid black;
  display: flex;
  align-items: stretch;
  flex-flow: column nowrap;
}

.AnaestheticChart__row-4 .AnaestheticChart__col {
  flex: 1 0 25%;
}

.AnaestheticChart__col .AnaestheticChart__col,
.AnaestheticChart__col:last-child {
  border-right: none;
}

.AnaestheticChart__col > [class^='AnaestheticChart__section'] {
  padding: 5px;
}

.AnaestheticChart__section-divider {
  border-top: 1px solid black;
}

[class^='AnaestheticChart__section'] dl {
  margin: 0;
  padding: 0;
  display: flex;
  flex-flow: row wrap;
}

[class^='AnaestheticChart__section'] dt {
  font-family: 'Open Sans';
  font-weight: 600;
  flex: 1 0 50%;
  margin: 0 0 10px;
}

[class^='AnaestheticChart__section'] dd {
  flex: 1 0 50%;
  margin: 0 0 10px;
}

.AnaestheticChart__section-heading {
  padding: 0;
  margin: 0 0 5px;
  font-size: 14px;
  font-family: 'Open Sans';
  font-weight: 600;
}

.AnaestheticChart__section-content-empty {
  height: 15px;
}

.AnaestheticChart__section-content + .AnaestheticChart__section-heading {
  margin-top: 8px;
}

.AnaestheticChart__row-large {
  min-height: 200px;
}

.AnaestheticChart__section--inline {
  margin: 0 inherit;

  .AnaestheticChart__section-heading {
    display: inline-block;
    margin-right: 0.5rem;
  }

  .AnaestheticChart__section-content {
    display: inline-block;
  }
}

.video-link {
  display: inline;
  cursor: pointer;
  border-bottom: 1px solid;
}

.redirect-message-container {
  text-align: center;
  color: #ffffff;
  margin-top: 1em;
  margin-bottom: 2em;
}

.ChaptersList {
  font-family: 'Open Sans';
  position: relative;
  background-color: rgba(11, 85, 108, 0.9);
  border-radius: 1000px;
  padding: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 500px;
  width: 500px;
  margin: auto;
  margin-bottom: 2rem;

  .Episode__consultant {
    margin-left: var(--token-spacing-3xs);
  }
}

.ChaptersList-inner {
  height: 310px;
  width: 310px;
  border-radius: 50%;
  background: white;

  .EpisodeInfo__edit {
    font-size: 14px;
    margin-left: 0.5rem;
    color: #00556e;
    display: block;
  }
}
.ChaptersList__link {
  display: block;
  width: 6em;
  height: 6em;
}

.ChaptersList__icon {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 6em;
  height: 6em;
  margin: -3em;
  text-align: center;
  border-radius: 50%;
}

.ChaptersList__icon img {
  max-width: 100%;
  display: inherit;
}

.ChaptersList__link-badge {
  background: white;
  color: rgba(11, 85, 108);
  display: inline-block;
  width: 1rem;
  height: 1rem;
  border-radius: 8px;
  text-align: center;
  line-height: 1rem;
  font-size: 12px;
  font-weight: 600;
  font-family: 'Open Sans';
  text-decoration: none;
  position: absolute;
  left: 50%;
  margin-left: -8px;
  top: 10px;
  opacity: 0.8;
}

/* 250px = half the width of the .circle-container  */

.position--0 {
  transform: rotate(288deg) translate(225px) rotate(-288deg);
}

.position--1 {
  transform: rotate(324deg) translate(225px) rotate(-324deg);
}

.position--2 {
  transform: translate(225px);
}

.position--3 {
  transform: rotate(36deg) translate(225px) rotate(-36deg);
}

.position--4 {
  transform: rotate(72deg) translate(225px) rotate(-72deg);
}

.position--5 {
  transform: rotate(108deg) translate(225px) rotate(-108deg);
}

.position--6 {
  transform: rotate(144deg) translate(225px) rotate(-144deg);
}

.position--7 {
  transform: rotate(180deg) translate(225px) rotate(-180deg);
}

.position--8 {
  transform: rotate(216deg) translate(225px) rotate(-216deg);
}

.position--9 {
  transform: rotate(252deg) translate(225px) rotate(-252deg);
}

.Episode__info--ChaptersList {
  color: #00556e;
  text-align: center;
  background-color: transparent;
  padding: 30px;
  height: 250px;
  width: 250px;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  flex: 0 1 auto;
  position: relative;
}

.Episode__info--ChaptersList > * {
  width: 100%;
}

.Episode__info--ChaptersList h1 {
  margin-top: 0;
  font-size: 1.5em;
  width: 200px;
}

.Episode__info--ChaptersList p {
  font-size: 12px;
  margin-top: 0;
  margin-bottom: 0;
}

.Episode__info--ChaptersList p:last-of-type {
  padding: 0 20px;
}

.ChaptersList-container {
  box-sizing: content-box;
}

.ChaptersList-container .button--state-change .loading-spinner {
  margin-right: 15px;
}

@media only screen and (max-width: 600px) {
  .ChaptersList-container .ChaptersList {
    zoom: 0.6;
  }
}

.ChaptersList__progress {
  width: 320px;
  height: 320px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.ChaptersList__progress-ring {
  position: relative;
  display: none;

  @media (min-width: 600px) {
    display: block;
  }
}

.ChaptersList__progress-circle-bg {
  stroke-dashoffset: 0;
  transform: rotate(-90deg);
  transform-origin: 50% 50%;
}

.ChaptersList__progress-circle {
  transition: stroke-dashoffset 2s ease-in-out;
  transform: rotate(-90deg);
  transform-origin: 50% 50%;
}

.ChapterList__progress-info {
  color: white;
  padding: 5px 0;
  margin: 0 0 10px;
}

.ChapterList__progress-started {
  background: #ec662f;
}

.ChapterList__progress-not-started {
  background: #ff0000;
}

.ChaptersList__actions .Button:only-child {
  display: inline-block;
}

.ChaptersList__action-confirm {
  font-size: 14px;
  line-height: 1.5;
  background: white;
  color: black;
  text-align: left;
  padding: 10px;
  max-width: 600px;
  margin: 10px auto 0;
}

.ChaptersList__action-confirm p {
  margin: 0;
}

.ChaptersList__action-confirm-toggle-off,
.ChaptersList__action-confirm-hidden {
  display: none;
}

.ChaptersList__action-confirm-actions {
  margin-top: 10px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
}

.ChaptersList__action-confirm-actions .button--loading .button__text {
  display: inline-flex;
  align-items: center;
  margin-left: 10px;
}

.ChaptersConfirm {
  padding: 20px;
  background: white;
  box-sizing: border-box;
}

.ChaptersConfirm__main {
  color: #00556e;
  margin-left: auto;
  margin-right: auto;
  max-width: 1000px;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
}
.ChaptersConfirm__main p {
  padding: 0;
  margin: 0 0 10px;
}

.ChaptersConfirm__main .ChaptersConfirm__actions {
  margin-top: 20px;
  text-align: center;
}

.ChaptersConfirm__main .ChaptersConfirm__actions .button {
  margin: 0 10px;
}

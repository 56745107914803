.Chapter {
  /* form container */
  .questionnaire {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }

  .SurveyTraditionalLayout {
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  }

  .questionnaire--patient {
    max-width: 1140px;
  }

  .questionnaire__title-block {
    text-align: center;
    color: #fff;
    margin: 36px auto 0;
  }

  .questionnaire__title-block-back-link {
    appearance: none;
    border: 0;
    background: transparent;
    margin: 0;
    padding: 0;
    cursor: pointer;
  }

  .questionnaire__title-block__icon {
    max-width: 5em;
    max-height: 5em;
    margin-bottom: -36px; /* Cancel out PageHeader top margin */
  }

  .questionnaire__title-block__title {
    margin-top: 0;
  }
}

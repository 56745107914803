
.bold {
  font-weight: bold;
}

.delete {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1em;
  gap: 0.1rem;

  background: #fbf0f0;
  border-radius: 4px;

  &__warning {
    display: flex;
    flex-direction: column;
    gap: 0.3rem;
    margin-right: 0.5rem;

    span:first-child {
      font-weight: bold;
    }
  }

  &__button-container {
    display: flex;
    justify-content: flex-end;
    gap: 0.5rem;
  }

  &__button {
    justify-self: center;
  }

  &__keep-item {
    justify-self: center;
    font-size: 0.9rem;
    padding: 0;
    border: none;
    background: none;
    text-decoration: underline;

    &:hover,
    &:focus {
      cursor: pointer;
    }
  }
}

// The Dynamic matrix is styled by Table.scss

.SurveyLayout .sv_q_matrix,
.SurveyTraditionalLayout .sv_q_matrix,
.SurveyTraditionalLayout .sv_q_matrix,
.SurveyTraditionalLayout .sv_q_matrix {
  border-collapse: collapse;

  tbody tr {
    background: #fff;

    &:nth-child(odd) {
      background: #f0f0f0;
    }
  }

  td,
  th {
    padding: 0.75rem;
    text-align: center;
    min-width: 90px;
  }

  label {
    text-align: center;
  }

  td:first-child {
    text-align: left;
  }

  .sv_q_m_label {
    position: relative !important;
  }

  .sv_q_m_cell {
    font-weight: 600;
  }
}

@import 'src/Shared/styles/variables';

.welcome {
  margin-top: 1.5rem;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  flex-wrap: wrap;
  gap: 1rem;
  word-break: break-word;

  h1 {
    color: #0d1c30;
    margin: 0;
  }

  img {
    width: 100%;
    max-width: 175px;
    margin: 1rem 4rem 1rem 0;
  }
}

.intro {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-left: 2rem;
}

.patientInfo {
  padding-right: 2rem;
  padding-bottom: 1rem;
  margin-left: 2rem;
}

.patientInfo div {
  margin-top: 0.75rem;
}

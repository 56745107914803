@import 'src/Shared/styles/variables';

.AuditTrailItem {
  display: table;
  width: 100%;
  margin: 0 0 6px 6px;
  font-size: 12px;
}

.AuditTrailItem--before {
  color: $grey;
}

.AuditTrailItem-description {
  display: table-cell;
  width: 75%;
}

.AuditTrailItem-actor {
  display: table-cell;
  text-align: right;
  width: 25%;
  padding-right: 6px;
}

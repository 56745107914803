.ProcedureCodeForm {
  padding: 2rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);

  .ProcedureCodeForm__CodeType-container {
    margin-bottom: 1rem;
  }

  .ButtonLayout {
    margin-top: 0;
  }
}

.ProcedureCodeForm form {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 0 4rem;
}

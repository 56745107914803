@import 'src/Shared/styles/variables';

.Callout {
  color: #000;
  border-left: 4px solid #005671;
  padding: 7px 7px 7px 15px;
}

.Callout--attention {
  border-color: var(--token-color-danger-default);
}

.Callout p {
  line-height: 1.4;
  font-size: 1rem;
}

.Callout :first-child {
  margin-top: 0;
}

.Callout :last-child {
  margin-bottom: 0;
}

.SurveyTraditionalLayout [role='radiogroup'] fieldset,
.SurveyLayout:not(.SurveyLayout--large-radio-buttons)
  [role='radiogroup']
  fieldset {
  :not(.sv_q_select_column) .sv_q_radiogroup:first-child,
  :not(.sv_q_select_column)
    .sv_q_radiogroup:first-child
    + .sv_q_radiogroup:last-child {
    // Inline when only two answers (such as Yes No)
    display: inline;
    margin-top: 0;
  }

  .sv_q_radiogroup {
    margin-top: 1rem;
  }

  .sv-string-viewer {
    margin-left: 0.5rem;
  }
}

.SurveyLayout--large-radio-buttons [role='radiogroup'] fieldset,
.HospitalRecoveryEntry [role='radiogroup'] fieldset {
  padding-bottom: 1.67em;
  display: grid;
  grid-template-columns: repeat(auto-fit, 125px);
  row-gap: 2rem;
  margin: 0 auto;
  justify-content: center;

  .RadioButton {
    border: 2px solid currentColor;
    border-radius: 100%;
    background-color: transparent;
    width: 36px;
    height: 36px;
    display: block;
    text-align: center;
    margin: 0 auto;
    margin-bottom: 1rem;
    position: relative;
  }

  .sv_q_radiogroup {
    padding-right: 0;
  }

  .sv_q_radiogroup_label {
    display: block;
    cursor: pointer;
    margin-inline: 1rem;
  }

  .sv_q_radiogroup_control_item {
    appearance: none;
    position: absolute;
    height: 1px !important;
    width: 1px !important;
    overflow: hidden;
    clip: rect(1px, 1px, 1px, 1px);

    margin-right: 0.5rem;

    &:checked + .RadioButton::before {
      content: '';
      border-color: currentColor;
      background-color: currentColor;
      border-radius: 50%;
      position: absolute;
      top: 8px;
      left: 8px;
      width: 16px;
      height: 16px;
    }

    &:disabled + .RadioButton {
      color: #666;

      @media not print {
        opacity: 0.6;
      }
    }

    &:disabled ~ span .sv-string-viewer {
      color: #666;
    }
  }
}

// .SurveyTraditionalLayout [role='radiogroup'] {
//   padding: 0.5rem 0 0 0;

//   .sv_q_radiogroup {
//     width: initial !important;
//     margin-right: 0.5rem;
//     margin-bottom: 1rem;
//   }

//   .sv_q_radiogroup_label {
//     display: flex;
//     align-items: center;
//   }

//   .sv-radio__svg,
//   .sv_q_radiogroup_label .RadioButton {
//     width: 1rem;
//     height: 1rem;
//     margin-bottom: 0;
//     margin-right: 0.2rem;
//   }
// }

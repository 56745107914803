.Card > div {
  background-color: #fff;
  padding: var(--token-spacing-inset-xl);
  margin-bottom: 0;

  @media (max-width: 599px) {
    border-radius: 0;

    padding-left: var(--token-spacing-md);
    padding-right: var(--token-spacing-md);

    margin-left: calc(var(--token-spacing-md) * -1);
    margin-right: calc(var(--token-spacing-md) * -1);
  }

  @media (min-width: 600px) {
    border-radius: var(--token-border-radius-sm);
    padding: var(--token-spacing-inset-xl);
  }
}

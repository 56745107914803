.Print-container {
  display: flex;
  flex-flow: column nowrap;
  height: 100%;

  .Button {
    margin-left: 0;
  }

  .embed {
    justify-self: stretch;
    flex-grow: 1;
    display: flex;
    flex-flow: column nowrap;
    justify-content: stretch;
  }

  .Print-embed {
    height: 100%;
    width: 100%;
    flex-grow: 1;
  }
}

#print-content {
  width: 1200px; // Ensures device/viewport size doesn't affect print layout.
}

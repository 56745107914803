@import 'src/Shared/styles/variables';

.Chapter {
  --survey-padding-left: 20px;
  --survey-padding-right: 20px;
}

.SurveyTraditionalLayout {
  box-sizing: border-box;
  background-color: #fff;

  .sv_q_text_root {
    width: 35ch;
    max-width: 100%;
    padding: var(--token-spacing-inset-squish-sm);
    border-radius: var(--token-border-radius-xs);
    border: 1px solid var(--token-color-border-secondary-default);
    line-height: 1;
  }

  .sv_q_title {
    font-size: 1rem;
    line-height: 1.4;
    margin-top: 0;
    margin-bottom: 1rem;
    font-weight: 600;
  }

  .sv_q_erbox {
    color: var(--token-color-danger-default);
    font-weight: 600;
    font-weight: 0.9rem;
    margin-bottom: 1rem;
  }

  .sv_q_description {
    margin-bottom: 1rem;
  }

  .sv_qstn {
    padding: 2rem 0;
    overflow: visible;
  }

  .PoaSummary & {
    .sv_qstn {
      padding: 0;
    }

    .sv_row .sv_row + .sv_row .sv_qstn {
      padding-top: 2rem;
      padding-bottom: 0;
    }
  }

  .sv_p_title {
    padding-left: 0;
  }

  .sv_q_footer {
    display: flex;

    .sv_matrix_dynamic_button {
      margin-left: auto;
    }
  }

  textarea {
    resize: vertical;
  }

  .sv_nav {
    display: flex;
    justify-content: space-between;
    padding-left: var(--survey-padding-left);
    padding-right: var(--survey-padding-right);
  }

  .sv_q_required_text {
    font-weight: 400;
    font-size: 0.9rem;
  }

  .sv_q_checkbox_control_label {
    margin-left: 0.5rem;
  }

  textarea {
    width: 90%;
    resize: vertical;
  }

  input[type='text'],
  input[type='number'] {
    width: 35ch;
    max-width: 100%;
  }

  input[type='date'] {
    width: 20ch;
    max-width: 100%;
  }

  .sv-action--hidden {
    display: none;
  }

  .Survey__back {
    margin-right: auto;
  }

  .Survey__next,
  .Survey__complete {
    margin-left: auto !important;
  }

  .Survey__clinical-question.Survey__clinical-summary {
    padding: 1rem !important;
    margin: 1rem;
    margin-bottom: 0;
    padding-bottom: 0;
    background: #fff3cd;
    border: 1px solid #856404;
    border-bottom: 0;
    color: #000;

    align-items: flex-end;
    margin-top: auto;

    .sv_qstn {
      padding: 0;
    }

    textarea {
      width: 100%;
      resize: vertical !important;
      min-height: 100px;
      padding: 6px;
      line-height: 1.2;
    }
  }

  .Survey__clinical-summary + .Survey__clinical-summary {
    border-top: 0;
    margin-top: 0;
    padding-top: 0;
    margin-bottom: 1rem;
  }

  .Survey__clinical-summary:last-of-type {
    border-bottom: 1px solid #856404;
    padding-bottom: 1rem;
  }

  // TODO: Merge separators (used for POA summary) and the :before together.
  &:not(.SurveyTraditionalLayout--separators) {
    .Chapter & .sv_p_root > .sv_row ~ .sv_row:not(.Survey__clinical-question):before, // Full HQ
    .HospitalShortHq & .sv_p_root > .sv_row ~ .sv_row .sv_row:not(.Survey__clinical-question):before // Short HQ
    {
      border-top: 1px solid #ccc;
      content: '';
      display: block;
      position: absolute;
      margin-left: calc(var(--survey-padding-left) * -1);
      width: 100%;
    }
  }

  .Survey__clinical-comment {
    p {
      text-decoration: underline;
    }
  }

  .sv_row {
    position: relative;

    @media print {
      margin-bottom: 0;
      break-inside: avoid;
      break-after: auto;
      break-before: auto;
    }
  }

  .copyToField {
    position: absolute;
    right: 50%;
    margin: 0;
    padding: 0;
    text-align: center;
    font-size: 40px;
    line-height: 44px;
    width: 46px;
    height: 46px;
    z-index: 1;
    background: #7cbde3;
    color: #fff;
    cursor: pointer;
    overflow: hidden;
    border-radius: 23px;
    border: 3px solid #fff;

    &:hover {
      background: #2793d1;
    }

    .sv_chevron {
      margin-top: 0;
      margin-left: 7px;
      display: block;

      transform: rotate(90deg);
    }
  }
}

.SurveyTraditionalLayout--hide-descriptions .sv_q_description {
  display: none;
}

.SurveyTraditionalLayout--separators {
  .sv_p_container {
    padding: 2rem 0;

    @media print {
      padding: 1rem 0;
    }

    .sv_row:first-child .sv_q_title {
      margin-top: 0;
    }
  }

  .sv_row {
    margin-bottom: 0;
  }

  .sv_p_root > .sv_row + .sv_row {
    border-top: 1px solid #999;

    .PoaSummary & {
      border-color: #7cbde3;
    }
  }
}

@media (max-width: 1000px) {
  .Chapter .SurveyTraditionalLayout {
    padding-right: 0;
    padding-left: 0;
  }

  .sv_row {
    padding-left: var(--survey-padding-left);
    padding-right: var(--survey-padding-right);
  }

  .Survey__clinical-question {
    background-color: #fef2f6;
  }
}

@media (min-width: 1000px) {
  .SurveyTraditionalLayout {
    padding-left: var(--survey-padding-left);
    padding-right: var(--survey-padding-right);
  }

  .SurveyTraditionalLayout--clinical {
    .sv_p_container > div[id*="_content"]:first-child,
    .triage-questionaire & .sv_body > .sv_p_root // Full HQ
    {
      display: grid !important;
      grid-template-columns: 1fr 1fr;

      background: linear-gradient(
        90deg,
        #fff 0%,
        #fff 50%,
        #fef2f6 50%,
        #fef2f6 100%
      );
    }

    .sv_qstn {
      padding-bottom: 2rem;
      padding-right: 1rem;
    }

    .Chapter & .sv_p_root > .sv_row ~ .sv_row:not(.Survey__clinical-question), // Full HQ
    .HospitalShortHq & .sv_p_root > .sv_row ~ .sv_row .sv_row:not(.Survey__clinical-question) // Short HQ
    {
      padding-right: 10px;

      &:before {
        width: 200% !important;
      }
    }

    .Chapter & .sv_p_root > .sv_row ~ .sv_row:not(.Survey__clinical-question), // Full HQ
    {
      &:before {
        width: calc(200% + var(--survey-padding-left)) !important;
      }
    }

    .Survey__clinical-question {
      background-color: transparent;
    }

    .sv_row {
      grid-column: 1;
    }

    .sv_row.Survey__clinical-question {
      grid-column: 2;
      padding-left: 2rem;
      padding-right: 0;
    }

    .sv_nav:not(:empty) {
      background: #fff;
      padding-top: 2rem;
      margin-top: 0;
    }

    .copyToField {
      right: -20px;
      top: 50%;
      transform: translateY(-50%);

      .sv_chevron {
        margin-top: -4px;
        margin-left: 3px;

        transform: rotate(0);
      }
    }
  }
}

.FilePreviewModal__image-container {
  margin-top: 1rem;
  display: flex;
  width: 100%;
  height: 90%;

  video {
    margin: 0 auto;
    max-width: 100%;
  }
}

.FilePreviewModal__fullscreen {
  object-fit: contain;
  margin: auto;
  max-width: 100%;
  max-height: 100%;
}

.FilePreviewModal__download {
  font-weight: 600;
}

@import 'src/Shared/styles/variables';

// This has overlap with .ProfileSearch and do both use the same dependency,
// however cannot currently use shared styling due to implementation differences.
.AutoSuggestConsultant {
  &.AutoSuggestConsultant--open {
    z-index: 11;
  }

  .react-autosuggest__suggestions-list {
    list-style: none;
    padding: 0;
  }

  &.AutoSuggestConsultant--open {
    .react-autosuggest__suggestions-container--open {
      position: absolute;
      left: 2rem;
      right: 2rem;
      margin-top: 0;
      max-height: 50vh;
      overflow-y: auto;
      z-index: 10;

      background-color: #fff;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      border-bottom-left-radius: 0.5rem;
      border-bottom-right-radius: 0.5rem;
      border: 1px solid #aaa;
      border-top: 0;
      box-shadow: 0 1px 3px 0 rgb(0 0 0 / 10%), 0 1px 2px 0 rgb(0 0 0 / 6%);
    }
  }

  @media (min-width: 950px) {
    position: relative;

    &.AutoSuggestConsultant--open {
      .react-autosuggest__suggestions-container--open {
        width: calc(100% + 2px);
        left: -1px;
        right: -2px;
        top: 1rem;
      }

      .react-autosuggest__container {
        position: relative;
      }

      .AutoSuggestConsultant__input {
        &:focus:not([value='']) {
          padding: 1rem;
          position: absolute;
          top: -2rem;
          padding-right: 3rem;
        }
      }
    }
  }

  .AutoSuggestConsultant__input {
    border: none;
    width: 100%;
    outline: none;
    padding: 0;
  }

  .AutoSuggestConsultant__suggestion-surgeon {
    margin-right: 2rem;
    line-height: 1.2em;
  }

  .AutoSuggestConsultant__suggestion-desc {
    font-size: 1rem;
  }

  .react-autosuggest__suggestion {
    cursor: pointer;
    padding: 1rem;
    border-bottom: 1px solid #eee;

    &:hover,
    &:focus,
    &.react-autosuggest__suggestion--highlighted {
      background-color: var(--token-color-row-highlight);
    }

    > div {
      display: flex;
      align-items: center;

      :first-child {
        flex-basis: 50px;
      }
    }
  }

  .AutoSuggestConsultant__loader {
    position: absolute;
    top: -1rem;
    right: 0.9rem;
    z-index: 12;
  }
}

.input--error .AutoSuggestConsultant,
.input--error .AutoSuggestConsultant input {
  color: #00556e;
}

/**
We are now using the variables set in design-tokens folder.
The Sass variables are generated. It is imported here as an alias.
Other variables here should be avoided/moved over time.
*/


// This is a duplicate of variables used for styled components.
$font-colour: #00556e;
$brand-colour-blue: #2793d1;
$hover-blue: #1d7db5;
$action-colour: #fff2f6;
$toggle-colour: #c4d600;
$white: #fff;
$black: #000;
$lighter-grey: #f7f7f7;
$light-grey: #e0e0e0;
$grey: #a1a1a1;
$dark-grey: #666666;
$light-blue: #dcf2f8;
$blue: #7cbde3;
$light-red: #f8d7da;
$dark-red: #721c24;
$light-green: #dff8dc;
$dark-green: #3d6917;

// Audit trail colours
$audit-trail-created: #95d656;
$audit-trail-shared: #bfd656;
$audit-trail-unshared: #d6bf56;
$audit-trail-description-edited: #56b0d6;
$audit-trail-deleted: #5686d6;
$audit-trail-started: #5656d6;
$audit-trail-unarchived: #7456d6;

// Password strength
$password-strength-weak: #e30303;
$password-strength-fair: #e38103;
$password-strength-good: #e3d603;
$password-strength-strong: #52e303;
$skip-link: #fd0;

.logo {
  display: inline-flex;
  user-select: none;

  &__glyph {
    margin-right: 12px;
    -webkit-user-drag: none;

    &--lifebox {
      width: 35px;
    }

    &--think {
      width: 45px;
    }
  }

  &__wordmark {
    margin-right: 24px;
    width: 135px;
    -webkit-user-drag: none;
  }
}

.logo--training {
  align-items: start;

  .logo__glyph {
    width: 24px;
  }

  .logo__wordmark {
    width: 108px;
    height: 40px;
  }
}

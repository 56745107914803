.FieldGroup {
  margin-bottom: var(--form-spacing);

  .FieldGroup__wrapper {
    margin-top: 0.5rem;
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
  }

  &.FieldGroup--horizontal .FieldGroup__wrapper {
    flex-direction: row;
    flex-wrap: wrap;
  }

  &.FieldGroup--vertical .FieldGroup__wrapper {
    .radio {
      margin-left: 0;
    }
  }

  &.input--center {
    margin-left: 1.2rem;

    .FieldGroup__wrapper {
      margin-top: 0;
    }
  }
}

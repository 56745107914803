@import 'src/Shared/styles/variables';

.AppUnauthenticated {
  background-color: var(--token-color-background-secondary-subtle);
  padding: 24px 12px 48px;
  margin: 0 -1rem;
  flex: 1;

  &:focus {
    border: 0;
    box-shadow: none;
    outline: none;
  }

  .Heading {
    font-size: 42px;
    margin-bottom: 12px;
  }

  .Intro {
    font-size: 18px;
    margin-bottom: 24px;
    line-height: 1.6;
  }
}

.AppUnauthenticated-inner {
  max-width: 700px;
  margin: 0 auto;
}

@import 'src/Shared/styles/variables';

// This version of the Matrix is used for the POA Summary
// to match DataTable.
.SurveyTraditionalLayout.SurveyTraditionalLayout--matrix-data-table {
  table.sv_q_matrix_dynamic {
    border-collapse: collapse;
    width: 100%;
    border: 0;
    padding: 0;
    margin: 0 0 20px;

    td,
    th {
      width: auto;
      padding: 5px 10px 5px 0;
      background: #fff;
    }

    th {
      text-align: left;
      font-weight: 600;
    }

    tbody tr:nth-child(odd) td {
      background: $light-blue;
    }
  }
}

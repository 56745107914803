.ButtonLayout {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;

  margin-top: 1.5rem;

  @media print {
    display: none !important;
  }

  .Button {
    margin: 0;
  }

  /**** Undo hacks from Button.scss - Using ButtonLayout regularly should mean hacks can be removed ****/
  a + .Button:not(.Button--fullWidth),
  button + .Button:not(.Button--fullWidth) {
    margin-left: initial;
  }

  .Button,
  input[type='button'].Button,
  .sv_container .Button {
    &:only-child {
      margin-left: initial;
      display: inline-block;
    }
  }

  &:empty {
    margin: 0;
    padding: 0;
  }
}

.ButtonLayout--space-between {
  justify-content: space-between;
}

.ButtonLayout--wrap {
  justify-content: center;
  padding-bottom: 0.5rem;

  .Button {
    margin: 0 !important;
  }
}

.ButtonLayout--basic,
.ButtonLayout--center {
  .Button {
    margin: 0;
    margin-right: 1rem;

    &:last-child {
      margin: 0;
    }
  }
}

.ButtonLayout--center {
  justify-content: center;
}

.ButtonLayout--stack {
  flex-direction: column;
  align-items: flex-end;

  .Button {
    width: max-content;
  }
}

.ButtonLayout--right {
  .Button {
    align-self: flex-end;
  }
}

.ButtonLayout--left {
  .Button {
    align-self: flex-start;
  }
}

.WidgetMatrix {
  :global(& .SurveyTraditionalLayout) {
    max-width: 500px;
  }
}

.MatrixRow {
  background: #fff;
  border: 1px solid var(--token-color-border-default);
  border-radius: var(--token-border-radius-sm);
  padding: var(--token-spacing-default);

  width: 100%;

  @media (min-width: 600px) {
    max-width: 520px;
  }
}

.AlignLeft {
  flex: 1;
  flex-basis: 200%;

  @media (min-width: 600px) {
    margin-right: auto;
    flex-basis: initial;
  }
}

@import 'src/Shared/styles/variables';

.PasswordStrengthMeter {
  color: $dark-grey;
  margin-bottom: 12px;
}

.PasswordStrengthMeter-progress {
  width: 100%;
  margin-top: 6px;
}

.PasswordStrengthMeter-score {
  height: 4px;
  border-radius: var(--token-border-radius-xs);
  transition: width 0.2s ease-in;
}

.PasswordStrengthMeter-score--none {
  width: 0;
  background-color: transparent;
}

.PasswordStrengthMeter-score--weak {
  width: 25%;
  background-color: $password-strength-weak;
}

.PasswordStrengthMeter-score--fair {
  width: 50%;
  background-color: $password-strength-fair;
}

.PasswordStrengthMeter-score--good {
  width: 75%;
  background-color: $password-strength-good;
}

.PasswordStrengthMeter-score--strong {
  width: 100%;
  background-color: $password-strength-strong;
}

.PasswordStrengthMeter-label {
  font-size: 18px;
}

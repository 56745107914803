@import 'Shared/styles/variables';

.HospitalEpisodesList_intro {
  margin-top: 0.75rem;

  span {
    margin-right: 1.25rem;
  }

  button {
    background: none;
    border: none;
    color: inherit;
    cursor: pointer;
    padding: 0;
    text-decoration: underline;
  }

  margin-bottom: 0.8rem;
  color: #555;
}

.HospitalEpisodesList_state {
  width: 52px;
}

.HospitalEpisodesList_patient,
.HospitalEpisodesList_consultant {
  min-width: 120px;
}

.HospitalEpisodesList_StateFilterWrapper {
  display: flex;
  overflow-x: auto;
  background-color: blue;
}

button.reset {
  color: #222;
  background: none;
  border: none;
  -webkit-appearance: none;
  font-weight: 600;
  font-size: 1rem;
  text-decoration: underline;
  cursor: pointer;
  margin-left: 1.5rem;

  &:hover,
  &:focus {
    color: #666;
  }
}

// ONLY FOR ON HOLD WORKFLOW TABLES

.PreOperativeList__state {
  font-weight: 600;
  &.PreOperativeList__state--primary {
    color: #585858;
  }
  &.PreOperativeList__state--review {
    color: #585858;
  }
  &.PreOperativeList__state--triageready {
    color: #ff8a00;
  }
  &.PreOperativeList__state--onhold {
    color: #217fb5;
  }
  &.PreOperativeList__state--notready {
    color: #585858;
  }
  &.PreOperativeList__state--ready {
    color: #018927;
  }
  &.PreOperativeList__state--archive {
    color: #000;
  }
  &.PreOperativeList__state--pending {
    color: #217fb5;
  }
}

.PreOperativeList__planStatusText {
  margin-left: 3px;
  vertical-align: middle;
}

.PreOperativeList__planStatus {
  display: block;
  width: 100%;
  text-decoration: none;
  font-weight: 600;

  max-width: 250px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  align-items: center;

  > svg {
    vertical-align: middle;
  }

  &.PreOperativeList__planStatus--CASE_COMPLETE {
    color: $black;
  }
  &.PreOperativeList__planStatus--PLAN_APPROVED {
    color: $black;
  }
  &.PreOperativeList__planStatus--PLAN_PENDING {
    color: $black;
  }
  &.PreOperativeList__planStatus--READY_FOR_REVIEW {
    color: $black;
  }
  &.PreOperativeList__planStatus--CHANGE_REQUESTED {
    color: #ff2b2bff;
  }
}

.PreOperativeList__loader {
  width: 100%;
  > span.Loader {
    margin: auto;
    margin-top: 0;
    display: block;
  }
}

.PreOperativeList__patient,
.PreOperativeList__procedure,
.PreOperativeList__consultant,
.PreOperativeList__hospital_number {
  display: block;
  width: 100%;
  text-decoration: none;
  color: #222;
  font-weight: 600;

  max-width: 225px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.PreOperativeList__hospital_number {
  max-width: 100px;
}

.PreOperativeList__procedure,
.PreOperativeList__consultant,
.PreOperativeList__hospital_number {
  font-weight: normal;
}

.PreOperativeList__patient:hover,
.PreOperativeList__procedure:hover,
.PreOperativeList__planStatus:hover,
.PreOperativeList__patient:focus,
.PreOperativeList__procedure:focus,
.PreOperativeList__planStatus:focus {
  text-decoration: underline;
}

.ExpandedComponent {
  position: relative;

  a {
    color: #00556e;
    font-weight: 600;
  }
}

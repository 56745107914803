@import 'src/Shared/styles/variables';

.container.hidden {
  top: -10px;
}

body:global(.trainingEnv) {
  .container {
    top: calc(77px + 36px); /*PatientHeader + TrainingBanner*/

    @media (min-width: 1024px) {
      top: calc(77px + 36px) !important;
    }
  }
}

.container {
  position: fixed;
  background: #fff;
  padding: 1rem;
  width: 100%;
  left: 0;
  top: 77px;
  transition: top 0.2s;
  box-shadow:
    0 1px 5px 0 rgba(0, 0, 0, 0.1),
    0 1px 3px 0 rgba(0, 0, 0, 0.1);
  z-index: 10;

  @media (min-width: 1024px) {
    top: 77px !important;
  }

  .inner {
    max-width: 1340px;
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    padding: 0 2rem;

    > a {
      display: flex;
      align-items: center;
      max-width: 265px;
      text-decoration: none;
      color: initial;
      cursor: pointer;
    }

    @media (max-width: 1023px) {
      :last-child {
        .number {
          margin-right: 0;
        }
      }
    }
  }

  .number {
    background-color: #666;
    color: #fff;
    padding: 0.5rem;
    border-radius: 50%;
    width: 2rem;
    height: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 1.25rem;
    flex-shrink: 0;
  }

  a.active {
    font-weight: bold;
    color: var(--token-color-text-heading-default);

    .number {
      background-color: var(--token-color-text-heading-default);
    }
  }

  .scrollProgress {
    height: 3px;
    background-color: #555;
    bottom: 0;
    position: absolute;
    left: 0;
  }

  @media (max-width: 1023px) {
    .text {
      display: none;
    }
  }
}

.Admin_UpdateRecovery {
  h3 {
    font-size: 1rem;
    margin-bottom: 0.5rem;
  }
}

.Admin_UpdateRecovery__procedure,
.Admin_UpdateRecovery__default {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 0 1rem;

  p {
    width: 100%;
    font-weight: 600;
    margin-top: 0;
    margin-bottom: 1.5rem;
  }

  .Form-label {
    font-size: 0.9rem;
  }
}

.Admin_UpdateRecovery__procedure {
  color: #000;
  border-left: 4px solid #005671;
  padding: 4px 7px 4px 15px;
  margin-top: 1.5rem;
}

.Admin_UpdateRecovery__default p,
.Admin_UpdateRecovery__procedure-list label {
  color: #005671;
  font-weight: 600;
}

.Admin_UpdateRecovery__procedure-list {
  margin-bottom: 2.5rem;
  margin-top: 1.5rem;
}

.Admin_UpdateRecovery__procedure-list .AutoSuggestProcedures__input {
  border: 2px solid #e4e4e4 !important;
  width: 100% !important;
  outline: none !important;
  border-radius: 4px !important;
  padding: 0.7rem 0.5rem !important;
  margin-top: 0.9rem !important;
  max-width: 100% !important;
}
